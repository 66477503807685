import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import {
    Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row
} from 'reactstrap';
import { AppSession, LoginType } from 'src/models/AppSession';
import { ILibraryConfig, IPublisher } from 'src/models/dto/DashboardModels';
import { FormState } from 'src/utilities/FormState';

import * as Models from '../../../models/dto/DashboardModels';
import { Image } from '../../foundation/Assets';
import { Icon } from '../../foundation/Controls';
import * as Messages from '../../foundation/Messages';
import { AppContext } from '../../state/Contextes';

export interface IPublisherLibraryConfigCreationFormProps {
  selectedPublisher: number;
}
export interface IPublisherLibraryConfigCreationFormState {
  publisherForm: FormState;
  libConfigForm: FormState;
  loginLogoTemp: string;
  libraryIconTemp: string;
  defaultBookSplashTemp: string;
  themeTemp: string;
  SplashFile: any;
  loginLogo: any;
  libraryIcon: any;
  theme: any;
}
export class PublisherLibraryConfigCreationForm extends React.Component<IPublisherLibraryConfigCreationFormProps, IPublisherLibraryConfigCreationFormState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IPublisherLibraryConfigCreationFormProps) {
    super(props);
    let publisherForm = new FormState({
      address: "",
      address2: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      phone: "",
      phone2: "",
      fax: "",
      fax2: "",
      email: "",
      email2: "",
      socialAddr: "",
      socialAddr2: "",
      url2: "",
      about: "",
      url: "",
      name: "",
      tableGuid: "",
      tableId: "",
    });
    let libConfigForm = new FormState({
      tableId: "",
      publisherId: "",
      lastModified: "",
      brandKey: "",
      version: "",
      authorizationId: 0,
      authorizationMethod: 0,
      productIdPrefix: "",
      stripePublicKey: "",
      stripePublicKeyTest: "",
      stripeStoreUrl: "",
      stripeStoreUrlTest: "",
      urlPrefix: "",
      theme: "",
      printMaxWordsCount: 0,
      copyMaxWordCountDaily: 0,
      hideRegistrationLink: false,
      isIAPEnabled: false,
      isAnonymousEnabled: false,
      isExternallyAddable: false,
      isLoginEmailBased: false,
      isRemeberMeAllowed: false,
      isServerUrlOverrideable: false,
      anonymousRequiresToken: false,
      loginLogo: "",
      defaultBookSplash: "",
      libraryIcon: "",
    });
    this.state = {
      publisherForm,
      libConfigForm,
      defaultBookSplashTemp: "",
      libraryIconTemp: "",
      loginLogoTemp: "",
      loginLogo: "",
      libraryIcon: "",
      theme: "",
      themeTemp: "",
      SplashFile: "",
    };
  }
  handlePublisherInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      publisherForm: this.state.publisherForm.change(event.target),
    });
  };
  handleLibConfigInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      libConfigForm: this.state.libConfigForm.change(event.target),
    });
  };
  handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkFileType(e)) {
      if (files && files.length > 0) {
        let reader = new FileReader();
        reader.readAsText(files[0]);

        reader.onload = () => {
          let basedata = reader.result;
          if (basedata === null) {
            return;
          }
          basedata = basedata.toString();
          this.setState({
            theme: basedata,
            themeTemp: basedata, // set temp src for image
          });
        };
        reader.onerror = (error) => error;
        Messages.Notify.success("File uploaded successfully");
      }
    }
  };
  checkFileType = (event: React.ChangeEvent<HTMLInputElement>) => {
    // getting file object
    let files = event.target.files;
    if (files === null) {
      return false;
    }
    // define message container
    let err = "";
    // list allow mime type
    const types = ["text/css", ""];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files![x].type !== type)) {
        // create error message and assign to container
        err = "Invalid file type (expected file type is .css or .scss)";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = ""; // discard selected file
      console.log(err);
      Messages.Notify.error(err);
      return false;
    }
    return true;
  };
  checkImageType = (event: any) => {
    // getting file object
    let files = event.target.files;
    // define message container
    let err = "";
    // list allow mime type
    const types = ["image/png", "image/jpeg"];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err = "Invalid file type (expected file type is .png or .jpeg)";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      Messages.Notify.error(err);
      return false;
    }
    return true;
  };

  getBase64 = (file: Blob, stateFunction: (basedata: string) => void): void => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let basedata = reader.result;
      if (basedata === null) {
        return;
      }
      basedata = basedata.toString();
      // Validating file type
      if (!basedata.includes("png")) {
        alert("Invalid file type");
        return;
      }
      // use regex to remove headers (data:image/png)
      if (basedata.includes("data")) {
        basedata = basedata.replace("data:", "").replace(/^.+,/, "");
      }
      stateFunction(basedata);
    };
    reader.onerror = (error) => error;
  };

  onSplashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkImageType(e)) {
      if (files && files.length > 0) {
        this.getBase64(files[0], (basedata) => {
          this.setState({
            SplashFile: basedata,
            defaultBookSplashTemp: URL.createObjectURL(files![0]), // set temp src for image
          });
        });
      }
      Messages.Notify.success("Image uploaded successfully");
    }
  };
  onLoginLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkImageType(e)) {
      if (files && files.length > 0) {
        this.getBase64(files[0], (basedata) => {
          this.setState({
            loginLogo: basedata,
            loginLogoTemp: URL.createObjectURL(files![0]), // set temp src for image
          });
        });
      }
      Messages.Notify.success("Image uploaded successfully");
    }
  };
  onLibraryIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkImageType(e)) {
      if (files && files.length > 0) {
        this.getBase64(files[0], (basedata) => {
          this.setState({
            libraryIcon: basedata,
            libraryIconTemp: URL.createObjectURL(files![0]), // set temp src for image
          });
        });
      }
      Messages.Notify.success("Image uploaded successfully");
    }
  };
  formatDate(date: Date) {
    let d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return [year, month, day].join("-");
  }

  submitPublisherLibConfig = async (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.state.publisherForm.values === undefined) {
      return;
    }
    let publisher: IPublisher = {
      About: this.state.publisherForm.values.about,
      Address: this.state.publisherForm.values.address,
      Address2: this.state.publisherForm.values.address2,
      City: this.state.publisherForm.values.city,
      Country: this.state.publisherForm.values.country,
      Email: this.state.publisherForm.values.email,
      Email2: this.state.publisherForm.values.email2,
      Phone: this.state.publisherForm.values.phone,
      Phone2: this.state.publisherForm.values.phone2,
      Fax: this.state.publisherForm.values.fax,
      Fax2: this.state.publisherForm.values.fax2,
      Name: this.state.publisherForm.values.name,
      PostalCode: this.state.publisherForm.values.postalCode,
      Province: this.state.publisherForm.values.province,
      URL: this.state.publisherForm.values.url,
      URL2: this.state.publisherForm.values.url2,
      SocialAddr: this.state.publisherForm.values.socialAddr,
      SocialAddr2: this.state.publisherForm.values.socialAddr2,
      TableGuid: "00000000-0000-0000-0000-000000000000",
      TableId: 0,
    };

    let config: ILibraryConfig = {
      TableId: 0,
      PublisherId: -1,
      LastModified: new Date(),
      BrandKey: this.state.libConfigForm.values.brandKey,
      Version: this.state.libConfigForm.values.version,
      AuthorizationId: this.state.libConfigForm.values.authorizationId,
      AuthorizationMethod: this.state.libConfigForm.values.authorizationMethod,
      ProductIdPrefix: this.state.libConfigForm.values.productIdPrefix,
      StripePublicKey: this.state.libConfigForm.values.stripePublicKey ?? null,
      StripePublicKeyTest: this.state.libConfigForm.values.stripePublicKeyTest ?? null,
      StripeStoreUrl: this.state.libConfigForm.values.stripeStoreUrl ?? null,
      StripeStoreUrlTest: this.state.libConfigForm.values.stripeStoreUrlTest ?? null,
      URLPrefix: this.state.libConfigForm.values.urlPrefix,
      Theme: this.state.libConfigForm.values.theme,
      PrintMaxWordCount: this.state.libConfigForm.values.printMaxWordsCount,
      CopyMaxWordCountDaily: this.state.libConfigForm.values.copyMaxWordCountDaily,
      HideRegistrationLink: this.state.libConfigForm.values.hideRegistrationLink,
      IsIAPEnabled: this.state.libConfigForm.values.isIAPEnabled,
      IsLoginEmailBased: this.state.libConfigForm.values.isLoginEmailBased,
      IsAnonymousEnabled: this.state.libConfigForm.values.isAnonymousEnabled,
      IsExternallyAddable: this.state.libConfigForm.values.IsExternallyAddable,
      IsRememberMeAllowed: this.state.libConfigForm.values.isRememberMeAllowed,
      AnonymousRequiresToken: this.state.libConfigForm.values.anonymousRequiresToken,
      IsServerUrlOverrideable: this.state.libConfigForm.values.isServerUrlOverrideable,
      LoginLogo: this.state.loginLogo ?? this.state.libConfigForm.values.loginLogo,
      DefaultBookSplash: this.state.SplashFile ?? this.state.libConfigForm.values.defaultBookSplash,
      LibraryIcon: this.state.libraryIcon ?? this.state.libConfigForm.values.libraryIcon,
      AvailableTitlesSectionDefaultsCollapsed: this.state.libConfigForm.values.availableTitlesSectionDefaultsCollapsed,
      AvailableTitlesSectionDisabled: this.state.libConfigForm.values.availableTitlesSectionDisabled,
      BuiltInLinkingOverride: this.state.libConfigForm.values.BuiltInLinkingOverride,
      BuiltInServerOverride: this.state.libConfigForm.values.IsServerUrlOverrideable,
      IsMicrosoftSsoEnabled: this.state.libConfigForm.values.IsMicrosoftSsoEnabled,
      MicrosoftTenantOverride: this.state.libConfigForm.values.MicrosoftTenantOverride,
    };

    let response = await this.context.insertPublisherLibraryConfig({ Publisher: publisher, LibraryConfig: config });
    await this.context.updateLoginLibraries();

    if (response.valid()) {
      Messages.Notify.success(`The Publisher  ${publisher.Name} was successfully created with its Library Config!`);
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Creation failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  render() {
    if (
      this.context.login === LoginType.None ||
      !(this.context.userPermissions.some((x) => x.ManagePublishers) || this.context.userPermissions.some((x) => x.ManageSystem))
    ) {
      return (
        <div className="section">
          <h1>You cannot view this page</h1>
        </div>
      );
    }
    let publisherForm = this.state.publisherForm;
    let libConfigForm = this.state.libConfigForm;
    return (
      <div className="formContainer">
        <Form className="p-2" onSubmit={this.submitPublisherLibConfig}>
          <h3>Publisher Creation</h3>
          <Tabs defaultActiveKey="pub-info" id="uncontrolled-tab-example">
            <Tab eventKey="pub-info" title="Create Publisher">
              <Col style={{ padding: "6px" }}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.card />} /> &nbsp; Name
                    </InputGroupText>
                    <Input
                      type="text"
                      name="name"
                      value={publisherForm.values.name}
                      disabled={!this.context.canManageSystem()}
                      invalid={publisherForm.invalid("name")}
                      placeholder="Name"
                      onChange={this.handlePublisherInput}
                    />
                  </InputGroup>
                </FormGroup>
                <Row style={{ flex: "1", display: "flex" }}>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.address_book />} /> &nbsp; Address
                        </InputGroupText>
                        <Input
                          type="text"
                          name="address"
                          value={publisherForm.values.address}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("address")}
                          placeholder="Address"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.address_book />} /> &nbsp; Address 2
                        </InputGroupText>
                        <Input
                          type="text"
                          name="address2"
                          value={publisherForm.values.address2}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("address2")}
                          placeholder="Address2"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.location />} /> &nbsp; City
                        </InputGroupText>
                        <Input
                          type="text"
                          name="city"
                          value={publisherForm.values.city}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("city")}
                          placeholder="City"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.location />} />
                          &nbsp; Province
                        </InputGroupText>
                        <Input
                          type="text"
                          name="province"
                          value={publisherForm.values.province}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("province")}
                          placeholder="Province"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.location />} /> &nbsp; Country
                        </InputGroupText>
                        <Input
                          type="text"
                          name="country"
                          value={publisherForm.values.country}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("country")}
                          placeholder="Country"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.location />} /> &nbsp; Postal Code
                        </InputGroupText>
                        <Input
                          type="text"
                          name="postalcode"
                          value={publisherForm.values.postalcode}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("postalcode")}
                          placeholder="Postal Code"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.envelop />} /> &nbsp; Email
                        </InputGroupText>
                        <Input
                          type="email"
                          name="email"
                          value={publisherForm.values.email}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("email") && publisherForm.values.email !== ""}
                          placeholder="Email"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.envelop />} /> &nbsp; Email 2
                        </InputGroupText>
                        <Input
                          type="email"
                          name="email2"
                          value={publisherForm.values.email2}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("email2") && publisherForm.values.email2 !== ""}
                          placeholder="Email2"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.phone />} /> &nbsp; Phone
                        </InputGroupText>
                        <Input
                          type="text"
                          name="phone"
                          value={publisherForm.values.phone}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("phone") && publisherForm.values.phone !== ""}
                          placeholder="Phone"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.phone />} /> &nbsp; Phone 2
                        </InputGroupText>
                        <Input
                          type="text"
                          name="phone2"
                          value={publisherForm.values.phone2}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("phone2") && publisherForm.values.phone2 !== ""}
                          placeholder="Phone 2"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.phone />} /> &nbsp; Fax
                        </InputGroupText>
                        <Input
                          type="text"
                          name="fax"
                          value={publisherForm.values.fax}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("fax") && publisherForm.values.fax !== ""}
                          placeholder="Fax"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.phone />} /> &nbsp; Fax 2
                        </InputGroupText>
                        <Input
                          type="text"
                          name="fax2"
                          value={publisherForm.values.fax2}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("fax2") && publisherForm.values.fax2 !== ""}
                          placeholder="Fax 2"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.phone />} /> &nbsp; URL
                        </InputGroupText>
                        <Input
                          type="text"
                          name="url"
                          value={publisherForm.values.url}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("url") && publisherForm.values.url !== ""}
                          placeholder="url"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.phone />} /> &nbsp; URL 2
                        </InputGroupText>
                        <Input
                          type="text"
                          name="url2"
                          value={publisherForm.values.url2}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("url2") && publisherForm.values.url2 !== ""}
                          placeholder="url 2"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.phone />} /> &nbsp; Social Address
                        </InputGroupText>
                        <Input
                          type="text"
                          name="socialAddr"
                          value={publisherForm.values.socialAddr}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("socialAddr") && publisherForm.values.socialAddr !== ""}
                          placeholder="Social Address"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.phone />} /> &nbsp; Social Address 2
                        </InputGroupText>
                        <Input
                          type="text"
                          name="socialAddr2"
                          value={publisherForm.values.socialAddr2}
                          disabled={!this.context.canManageSystem()}
                          invalid={publisherForm.invalid("socialAddr2") && publisherForm.values.socialAddr2 !== ""}
                          placeholder="Social Address 2"
                          onChange={this.handlePublisherInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Tab>
            <Tab eventKey="lib-config" title="Create Library Config">
              <Col style={{ padding: "6px" }}>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.info />} /> &nbsp; Version
                        </InputGroupText>
                        <Input
                          type="text"
                          name="version"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.version}
                          invalid={libConfigForm.invalid("version")}
                          placeholder="Version"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.card />} /> &nbsp; Authorization Id
                        </InputGroupText>
                        <Input
                          type="select"
                          name="authorizationId"
                          value={libConfigForm.values.authorizationId}
                          placeholder={" Authorization Id"}
                          onChange={this.handleLibConfigInput}
                        >
                          {Object.keys(Models.AuthProviderType)
                            .filter((key) => isNaN(Number(Models.AuthProviderType[key as keyof typeof Models.AuthProviderType])))
                            .map((it) => (
                              <option value={it} key={it} data-providerval={it}>
                                {Models.AuthProviderType[it as keyof typeof Models.AuthProviderType]}
                              </option>
                            ))}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.card />} /> &nbsp; Authorization Method
                        </InputGroupText>
                        <Input
                          type="select"
                          name="authorizationMethod"
                          value={libConfigForm.values.authorizationMethod}
                          placeholder={" Authorization Method"}
                          onChange={this.handleLibConfigInput}
                        >
                          {Object.keys(Models.AuthMethod)
                            .filter((key) => isNaN(Number(Models.AuthMethod[key as keyof typeof Models.AuthMethod])))
                            .map((it) => (
                              <option value={it} key={it} data-providerval={it}>
                                {Models.AuthMethod[it as keyof typeof Models.AuthMethod]}
                              </option>
                            ))}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.key5 />} /> &nbsp; Stripe Public Key
                        </InputGroupText>
                        <Input
                          type="text"
                          name="stripePublicKey"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.stripePublicKey}
                          invalid={libConfigForm.invalid("stripePublicKey")}
                          placeholder="Stripe Public Key"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.key5 />} /> &nbsp; Stripe Public Key Test
                        </InputGroupText>
                        <Input
                          type="text"
                          name="stripePublicKeyTest"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.stripePublicKeyTest}
                          invalid={libConfigForm.invalid("stripePublicKeyTest")}
                          placeholder="Stripe Public Key Test"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.url />} /> &nbsp; Stripe Store Url
                        </InputGroupText>
                        <Input
                          type="text"
                          name="stripeStoreUrl"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.stripeStoreUrl}
                          invalid={libConfigForm.invalid("stripeStoreUrl") && libConfigForm.values.stripeStoreUrl !== ""}
                          placeholder="Stripe Store Url"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.url />} /> &nbsp; Stripe Store Url Test
                        </InputGroupText>
                        <Input
                          type="text"
                          name="stripeStoreUrlTest"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.stripeStoreUrlTest}
                          invalid={libConfigForm.invalid("stripeStoreUrlTest") && libConfigForm.values.stripeStoreUrlTest !== ""}
                          placeholder="Stripe Store Url Test"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.url />} /> &nbsp; URL Prefix
                        </InputGroupText>
                        <Input
                          type="text"
                          name="urlPrefix"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.urlPrefix}
                          invalid={libConfigForm.invalid("urlPrefix") && libConfigForm.values.urlPrefix !== ""}
                          placeholder="URL Prefix"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.card />} /> &nbsp; ProductId Prefix
                        </InputGroupText>
                        <Input
                          type="text"
                          name="productIdPrefix"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.productIdPrefix}
                          invalid={libConfigForm.invalid("productIdPrefix")}
                          placeholder="ProductId Prefix"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.key4 />} />
                          &nbsp; Brand Key
                        </InputGroupText>
                        <Input
                          type="text"
                          name="brandKey"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.brandKey}
                          invalid={libConfigForm.invalid("brandKey")}
                          placeholder="Brand Key"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.file_empty />} /> &nbsp; Print Max Word Count
                        </InputGroupText>
                        <Input
                          type="number"
                          name="printMaxWordsCount"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.printMaxWordsCount}
                          invalid={libConfigForm.invalid("printMaxWordsCount")}
                          placeholder="Print Max Word Count"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.files_empty />} /> &nbsp; Copy Max Word Count Daily
                        </InputGroupText>
                        <Input
                          type="number"
                          name="copyMaxWordCountDaily"
                          disabled={!this.context.canManageSystem()}
                          value={libConfigForm.values.copyMaxWordCountDaily}
                          invalid={libConfigForm.invalid("copyMaxWordCountDaily")}
                          placeholder="Copy Max Word Count Daily"
                          onChange={this.handleLibConfigInput}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="isIAPEnabled">
                        <Input
                          onChange={this.handleLibConfigInput}
                          checked={libConfigForm.values.isIAPEnabled}
                          disabled={!this.context.canManageSystem()}
                          type="checkbox"
                          name="isIAPEnabled"
                          id="isIAPEnabled"
                        />{" "}
                        Is IAP Enabled
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="isExternallyAddable">
                        <Input
                          onChange={this.handleLibConfigInput}
                          checked={libConfigForm.values.isExternallyAddable}
                          disabled={!this.context.canManageSystem()}
                          type="checkbox"
                          name="isExternallyAddable"
                          id="isExternallyAddable"
                        />{" "}
                        Is Externally Addable
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="isServerUrlOverrideable">
                        <Input
                          onChange={this.handleLibConfigInput}
                          checked={libConfigForm.values.isServerUrlOverrideable}
                          disabled={!this.context.canManageSystem()}
                          type="checkbox"
                          name="isServerUrlOverrideable"
                          id="isServerUrlOverrideable"
                        />{" "}
                        Is Server Url Overrideable
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="anonymousRequiresToken">
                        <Input
                          onChange={this.handleLibConfigInput}
                          checked={libConfigForm.values.anonymousRequiresToken}
                          disabled={!this.context.canManageSystem()}
                          type="checkbox"
                          name="anonymousRequiresToken"
                          id="anonymousRequiresToken"
                        />{" "}
                        Anonymous Requires Token
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="isRememberMeAllowed">
                        <Input
                          onChange={this.handleLibConfigInput}
                          checked={libConfigForm.values.isRememberMeAllowed}
                          disabled={!this.context.canManageSystem()}
                          type="checkbox"
                          name="isRememberMeAllowed"
                          id="isRememberMeAllowed"
                        />{" "}
                        Is Remember Me Allowed
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "14px", paddingBottom: "14px" }}>
                  <Col>
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="hideRegistrationLink">
                        <Input
                          onChange={this.handleLibConfigInput}
                          checked={libConfigForm.values.hideRegistrationLink}
                          disabled={!this.context.canManageSystem()}
                          type="checkbox"
                          name="hideRegistrationLink"
                          id="hideRegistrationLink"
                        />
                        Hide Registration Link
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="isAnonymousEnabled">
                        <Input
                          onChange={this.handleLibConfigInput}
                          checked={libConfigForm.values.isAnonymousEnabled}
                          disabled={!this.context.canManageSystem()}
                          type="checkbox"
                          name="isAnonymousEnabled"
                          id="isAnonymousEnabled"
                        />{" "}
                        Is Anonymous Enabled
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="isLoginEmailBased">
                        <Input
                          onChange={this.handleLibConfigInput}
                          checked={libConfigForm.values.isLoginEmailBased}
                          disabled={!this.context.canManageSystem()}
                          type="checkbox"
                          name="isLoginEmailBased"
                          id="isLoginEmailBased"
                        />{" "}
                        Is Login Email Based
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.card />} /> &nbsp; Theme
                        </InputGroupText>
                        <Label style={{ paddingLeft: "10px", paddingTop: "10px" }} for="theme-input" className="input-label">
                          <i style={{ fontStyle: "italic" }}>Click to Upload (.sass files)</i>
                        </Label>
                        <Input type="file" name="theme" id="theme-input" onChange={this.handleFile} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup style={{ flex: "1" }}>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.card />} /> &nbsp; Login Logo
                        </InputGroupText>
                        <Label style={{ paddingLeft: "10px", paddingTop: "10px" }} for="logo-input" className="input-label">
                          <i style={{ fontStyle: "italic" }}>Click to Upload (.png) </i>{" "}
                        </Label>
                        <Input
                          type="file"
                          id="logo-input"
                          name="loginLogo"
                          disabled={!this.context.canManageSystem()}
                          invalid={libConfigForm.invalid("loginLogo") && libConfigForm.values.loginLogo !== ""}
                          placeholder="Login Logo"
                          onChange={this.onLoginLogoChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="preview-image">
                      <img title="loginLogo" src={this.state.loginLogoTemp ?? libConfigForm.values.loginLogo} width={75} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup style={{ flex: "1" }}>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.card />} /> &nbsp; Library Icon
                        </InputGroupText>
                        <Label style={{ paddingLeft: "10px", paddingTop: "10px" }} for="library-input" className="input-label">
                          <i style={{ fontStyle: "italic" }}>Click to Upload (.png) </i>
                        </Label>
                        <Input
                          type="file"
                          id="library-input"
                          name="libraryIcon"
                          disabled={!this.context.canManageSystem()}
                          invalid={libConfigForm.invalid("libraryIcon") && libConfigForm.values.libraryIcon !== ""}
                          placeholder="Library Icon"
                          onChange={this.onLibraryIconChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="preview-image">
                      <img title="libraryIcon" src={this.state.libraryIconTemp ?? libConfigForm.values.libraryIcon} width={75} />
                    </div>
                  </Col>
                  <Col>
                    <FormGroup style={{ flex: "1" }}>
                      <InputGroup>
                        <InputGroupText>
                          <Icon src={<Image.card />} /> &nbsp; Default Book Splash
                        </InputGroupText>

                        <Label style={{ paddingLeft: "10px", paddingTop: "10px" }} for="file-input" className="input-label">
                          <i style={{ fontStyle: "italic" }}>Click to Upload (.png) </i>
                        </Label>
                        <Input
                          type="file"
                          id="file-input"
                          name="defaultBookSplash"
                          disabled={!this.context.canManageSystem()}
                          invalid={libConfigForm.invalid("defaultBookSplash") && libConfigForm.values.defaultBookSplash !== ""}
                          placeholder="Default Book Splash"
                          onChange={this.onSplashChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="preview-image">
                      <img title="defaultBookSplash" src={this.state.defaultBookSplashTemp ?? libConfigForm.values.defaultBookSplash} width={75} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Tab>
          </Tabs>
          <FormGroup style={{ flex: "1", display: "flex", justifyContent: "center" }}>
            <Col xs={8}>
              <Button
                color="primary"
                outline
                block
                disabled={!(publisherForm.formValid() || libConfigForm.formValid())}
                onClick={this.submitPublisherLibConfig}
              >
                Save
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
