import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { AppSession } from 'src/models/AppSession';
import * as Models from 'src/models/dto/DashboardModels';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

export interface IAffiliateFormProps {
  initialNode: Models.IRegisteredAffiliate;
  publisherId: number;
  saveRequested?: (node: Models.IRegisteredAffiliate) => void;
  reloadAffiliates?: () => void;
  isEditing: boolean;
}

export interface IAffiliateFormState {
  editingNode: Models.IRegisteredAffiliate;
}

export class AffiliateForm extends React.Component<IAffiliateFormProps, IAffiliateFormState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IAffiliateFormProps) {
    super(props);
    this.state = {
      editingNode: {
        Index: props.initialNode.Index,
        IsFirst: props.initialNode.IsFirst,
        IsLast: props.initialNode.IsLast,
        TableId: props.initialNode.TableId,
        TableGuid: props.initialNode.TableGuid,
        PublisherId: props.initialNode.PublisherId,
        AffiliateCode: props.initialNode.AffiliateCode,
        StartDate: props.initialNode.StartDate,
        EndDate: props.initialNode.EndDate,
        ReductionType: props.initialNode.ReductionType,
        ReductionAmount: props.initialNode.ReductionAmount,
        IsActive: props.initialNode.IsActive,
      },
    };
  }

  affiliateCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        AffiliateCode: e.target.value,
      },
    }));
  };

  startDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        StartDate: new Date(e.target.valueAsDate!),
      },
    }));
  };

  endDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        EndDate: new Date(e.target.valueAsDate!),
      },
    }));
  };

  toggleReductionType = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => {
      const newReductionType = 1 - prevState.editingNode.ReductionType;
      const newReductionAmount = newReductionType === 1 && prevState.editingNode.ReductionAmount > 100 ? 10 : prevState.editingNode.ReductionAmount;
      return {
        editingNode: {
          ...prevState.editingNode,
          ReductionType: newReductionType,
          ReductionAmount: newReductionAmount,
        },
      };
    });
  };

  statusChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        IsActive: e.target.checked,
      },
    }));
  };

  reductionAmountChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value = e.target.valueAsNumber;
    if (!isNaN(value) && value >= 1) {
      if (this.state.editingNode.ReductionType === 1 && value > 100) {
        this.setState((prevState) => ({
          editingNode: {
            ...prevState.editingNode,
            ReductionAmount: 100,
          },
        }));
      } else {
        this.setState((prevState) => ({
          editingNode: {
            ...prevState.editingNode,
            ReductionAmount: e.target.valueAsNumber,
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        editingNode: {
          ...prevState.editingNode,
          ReductionAmount: 1,
        },
      }));
    }
  };

  formValid = () => {
    if (this.state.editingNode.AffiliateCode.length === 0 || this.state.editingNode.EndDate <= this.state.editingNode.StartDate) {
      return false;
    }
    return true;
  };
  render() {
    return (
      <div className="full-width full-height">
        <h2>Affiliate Management</h2>
        <p>
          Use this panel to manage your affiliates. Affiliates are external partners such as YouTubers, retailers, or influencers with whom we have business
          agreements. Each affiliate is assigned a unique code that tracks sales linked to their promotions. Discounts can be offered as either a percentage
          discount or a fixed amount on title purchases.
        </p>
        <Form>
          <Col>
            <Row>
              <FormGroup style={{ flex: "1" }}>
                <Label for="code">Code </Label>
                <Input onChange={this.affiliateCodeChanged} type="text" value={this.state.editingNode.AffiliateCode} name="code" id="code" width="8px" />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: "1" }}>
                <Label for="startDate">Start Date </Label>
                <Input
                  onChange={this.startDateChanged}
                  type="date"
                  value={Convert.formatDateForForm(new Date(this.state.editingNode.StartDate))}
                  name="startDate"
                  id="startDate"
                  width="8px"
                />
              </FormGroup>
              <FormGroup style={{ flex: "1" }}>
                <Label for="endDate">End Date </Label>
                <Input
                  onChange={this.endDateChanged}
                  type="date"
                  value={Convert.formatDateForForm(new Date(this.state.editingNode.EndDate))}
                  name="endDate"
                  id="endDate"
                  width="8px"
                />
              </FormGroup>

              <FormGroup check style={{ flex: "1" }}>
                <Label check for="status">
                  <Input onChange={this.statusChanged} type="checkbox" checked={this.state.editingNode.IsActive} name="status" id="status" width="8px" />
                  Active{" "}
                </Label>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: "1" }}>
                <Label for="discount">Discount</Label>
                <Input
                  onChange={this.reductionAmountChanged}
                  type="number"
                  value={this.state.editingNode.ReductionAmount}
                  name="discount"
                  id="discount"
                  min="1"
                  step="1"
                  style={{ width: "100px" }}
                />
                <FormGroup>
                  <Label check for="credits" style={{ marginLeft: "30px" }}>
                    <Input type="radio" value="Credits" checked={this.state.editingNode.ReductionType === 0} onChange={this.toggleReductionType} />
                    Credits
                  </Label>
                  <Label check for="percentage" style={{ marginLeft: "30px" }}>
                    <Input type="radio" value="Percentage" checked={this.state.editingNode.ReductionType === 1} onChange={this.toggleReductionType} />
                    Percentage
                  </Label>
                </FormGroup>
              </FormGroup>
            </Row>
          </Col>
          <Col>
            <Row className="formButtons">
              {this.props.saveRequested && (
                <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                  Save
                </Button>
              )}
            </Row>
          </Col>
        </Form>
      </div>
    );
  }
}
