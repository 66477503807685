import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { Loading } from 'src/ui/foundation/Controls';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { Action, IBatch, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import { SubscriptionForm } from '../SubscriptionView/SubscriptionForm';

export interface ICustomerFormProps {
  initialNode: Models.ICustomerViewModel;
  deleteRequested?: (node: Models.ICustomerViewModel) => void;
  saveRequested?: (node: Models.ICustomerViewModel) => void;
  reloadCustomers?: () => void;
}
export interface ICustomerFormState {
  editingNode: Models.ICustomerViewModel;
  editingSubscription: Models.ISubscriptionViewModel | null;
  editingContact: number;
  activeTab: CustomerTab;
  loading: boolean;
  subscriptionMode: boolean;
}
enum CustomerTab {
  Properties,
  Subscriptions,
  Contacts,
}
export class CustomerForm extends React.Component<ICustomerFormProps, ICustomerFormState> {
  context: AppSession;
  static contextType = AppContext;
  thumbnailInput = React.createRef<HTMLInputElement>();
  subscriptionsTable = React.createRef<DataTable>();
  contactTable = React.createRef<DataTable>();
  constructor(props: ICustomerFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      activeTab: CustomerTab.Properties,
      loading: false,
      editingContact: -1,
      subscriptionMode: false,
      editingSubscription: null,
    };
  }
  saveRequested = () => {
    if (this.props.saveRequested) {
      this.setState({ loading: true }, () => {
        this.props.saveRequested!(this.state.editingNode);
        this.setState({ loading: false });
      });
    }
  };
  deleteRequested = () => {
    if (this.props.deleteRequested) {
      this.props.deleteRequested(this.state.editingNode);
    }
  };

  // #region Properties
  handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Name: e.target.value } } }));
  };
  handleAddress1Changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Address1: e.target.value } } }));
  };
  handleAddress2Changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Address2: e.target.value } } }));
  };
  handleCityChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, City: e.target.value } } }));
  };
  handleProvinceChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Province: e.target.value } } }));
  };
  handleCountryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Country: e.target.value } } }));
  };
  handlePostalChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, PostalCode: e.target.value } } }));
  };
  handlePhoneChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Phone: e.target.value } } }));
  };
  handleFaxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Fax: e.target.value } } }));
  };
  handleWebChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Web: e.target.value } } }));
  };
  handleOtherChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, Other: e.target.value } } }));
  };
  handleNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Customer: { ...prevState.editingNode.Customer, CustomerNumber: e.target.value } },
    }));
  };
  // #endregion

  // #region Subscriptions
  private initializeSubscriptions = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.state.editingNode.Customer.TableId === -1) {
        reject();
        return;
      }
      let result = await this.context.flowCustomerSubscriptions({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: 75, TargetMainId: 0, Query: query },
        CustomerId: this.state.editingNode.Customer.TableId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private subscriptionFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowCustomerSubscriptions({ FlowRequest: request.Batches[0], CustomerId: this.state.editingNode.Customer.TableId });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  generateSubscription = (node: INode) => {
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Subscription.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={2} key={1} className="" value={node.ProductDef.Name as string} />);
    dataItems.push(
      <DataItem
        flexVal={1}
        key={2}
        className="rightBorder leftBorder centerText"
        value={Convert.dateToFormattedString(node.Subscription.StartDate as Date, Languages.English)}
      />
    );
    if (new Date(node.Subscription.EndDate as Date) < new Date()) {
      dataItems.push(
        <DataItem flexVal={1} className="centerText" key={3} value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.Subscription.EndDate as Date, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(
        <DataItem flexVal={1} key={3} className="centerText" value={Convert.dateToFormattedString(node.Subscription.EndDate as Date, Languages.English)} />
      );
    }
    dataItems.push(<DataItem flexVal={1} key={4} className="rightBorder leftBorder centerText" value={node.AssignedLicences.length.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={5} className="centerText" value={node.Subscription.TotalLicences.toString()} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.subscriptionEdit} />;
  };
  subscriptionEdit = (node: INode) => {
    this.setState({ editingSubscription: node as Models.ISubscriptionViewModel, subscriptionMode: true });
  };
  addSubscription = () => {
    let blank: Models.ISubscriptionViewModel = {
      AssignedLicences: [],
      Conditions: [],
      Index: -1,
      IsFirst: false,
      IsLast: false,
      ProductDef: null,
      Subscription: {
        AccessAfterExpiration: true,
        AuthSubscription: "",
        Canceled: false,
        ContentPermissions: 3,
        CustomerId: this.state.editingNode.Customer.TableId,
        DateCreated: new Date(),
        DefinitionId: -1,
        EndDate: new Date(),
        OfflineAccessDuration: 1,
        OfflineAccessDurationType: 0,
        OfflineLogins: 0,
        StartDate: new Date(),
        TableGuid: "00000000-0000-0000-0000-000000000000",
        TableId: 0,
        TotalLicences: 0,
      },
      // Customer: this.state.editingSubscription!.Customer!,
      Customer: this.state.editingNode.Customer,
    };
    this.setState({ editingSubscription: blank, subscriptionMode: true });
  };
  // #endregion

  private initializeContacts = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      let result = this.state.editingNode.Contacts;
      if (result === null || result === undefined) {
        reject();
        return;
      }
      let request: IBatch = {
        Action: Action.insert,
        AnchorMainId: 0,
        Nodes: [],
        BatchSize: Models.genericDataSettings.batchSize,
        TargetMainId: 0,
        Query: query,
      };
      request.Nodes = result;
      request.BatchSize = 10000;
      resolve({
        nodes: Convert.indexify(request).Nodes,
        targetSpine: 0,
      });
    });
  private contactFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve) => {
      resolve({ Batches: [] });
    });
  generateContact = (node: INode) => {
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={2} key={1} className="" value={node.FirstName as string} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="rightBorder leftBorder centerText" value={node.LastName as string} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="centerText" value={node.Email as string} />);
    dataItems.push(<DataItem flexVal={1} key={4} className="rightBorder leftBorder centerText" value={Models.LanguageCode[node.Language]} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.contactEdit} />;
  };
  contactEdit = (node: INode) => {
    this.setState({ editingContact: node.Index as number });
  };
  addContact = () => {
    let holder = this.state.editingNode.Contacts;
    let newContact: Models.IContact = {
      Email: "",
      FirstName: "",
      LastName: "",
      Language: 0,
      TableId: 0,
      Index: 0,
      IsFirst: false,
      IsLast: false,
    };
    holder.push(newContact);
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
        editingContact: holder.length - 1,
      }),
      () => this.contactTable.current!.reload()
    );
  };
  firstnameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let holder = this.state.editingNode.Contacts;
    holder[this.state.editingContact].FirstName = e.target.value;
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
      }),
      () => this.contactTable.current!.reload()
    );
  };
  lastnameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let holder = this.state.editingNode.Contacts;
    holder[this.state.editingContact].LastName = e.target.value;
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
      }),
      () => this.contactTable.current!.reload()
    );
  };
  emailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let holder = this.state.editingNode.Contacts;
    holder[this.state.editingContact].Email = e.target.value;
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
      }),
      () => this.contactTable.current!.reload()
    );
  };
  languageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let holder = this.state.editingNode.Contacts;
    holder[this.state.editingContact].Language = +e.target.value;
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
      }),
      () => this.contactTable.current!.reload()
    );
  };
  deleteContact = () => {
    let holder = this.state.editingNode.Contacts;
    holder.splice(this.state.editingContact, 1);
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
        editingContact: -1,
      }),
      () => this.contactTable.current!.reload()
    );
  };

  render() {
    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.batchSize = 75;
    if (this.state.subscriptionMode && this.state.editingSubscription !== null) {
      let subModel: Models.ISubscriptionViewModel = JSON.parse(JSON.stringify(this.state.editingSubscription));
      subModel.Customer = this.state.editingNode.Customer;
      return (
        <SubscriptionForm
          initialNode={subModel}
          publisherId={this.state.editingNode.Customer.PublisherId}
          goBackButtonClicked={() => this.setState({ editingSubscription: null, subscriptionMode: false })}
        />
      );
    } else {
      return (
        <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Customer...">
          <div className="form-container full-width full-height">
            <h3>Customer management: {Convert.isEmptyOrSpaces(this.state.editingNode.Customer.Name) ? "No name set" : this.state.editingNode.Customer.Name}</h3>
            <div className="customer-tabs">
              <Tabs activeKey={this.state.activeTab} onSelect={(k: any) => this.setState({ activeTab: k })} id="customerTabs">
                <Tab eventKey={CustomerTab.Properties} title={"Properties"}>
                  <div className="full-width full-height customerProperties">
                    <Col>
                      <Row>
                        <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                          <Label for="customerNumber">Customer Number</Label>
                          <Input
                            value={this.state.editingNode.Customer.CustomerNumber}
                            onChange={this.handleNumberChanged}
                            type="text"
                            name="customerNumber"
                            id="customerNumber"
                            placeholder="Customer Number"
                          />
                        </FormGroup>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="customerName">Customer Name</Label>
                          <Input
                            value={this.state.editingNode.Customer.Name}
                            onChange={this.handleNameChanged}
                            type="text"
                            name="customerName"
                            id="customerName"
                            placeholder="Customer Name"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                          <Label for="address">Address</Label>
                          <Input
                            value={this.state.editingNode.Customer.Address1}
                            onChange={this.handleAddress1Changed}
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Address"
                          />
                        </FormGroup>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="address2">Address Line 2</Label>
                          <Input
                            value={this.state.editingNode.Customer.Address2}
                            onChange={this.handleAddress2Changed}
                            type="text"
                            name="address2"
                            id="address2"
                            placeholder="Address Line 2"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                          <Label for="city">City</Label>
                          <Input
                            value={this.state.editingNode.Customer.City}
                            onChange={this.handleCityChanged}
                            type="text"
                            name="city"
                            id="city"
                            placeholder="City"
                          />
                        </FormGroup>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="province">Province or State</Label>
                          <Input
                            value={this.state.editingNode.Customer.Province}
                            onChange={this.handleProvinceChanged}
                            type="text"
                            name="province"
                            id="province"
                            placeholder="Province or State"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                          <Label for="country">Country</Label>
                          <Input
                            value={this.state.editingNode.Customer.Country}
                            onChange={this.handleCountryChanged}
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Country"
                          />
                        </FormGroup>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="postalCode">Postal Code or Zip</Label>
                          <Input
                            value={this.state.editingNode.Customer.PostalCode}
                            onChange={this.handlePostalChanged}
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            placeholder="Postal Code or Zip"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                          <Label for="phone">Phone</Label>
                          <Input
                            value={this.state.editingNode.Customer.Phone}
                            onChange={this.handlePhoneChanged}
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Phone"
                          />
                        </FormGroup>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="fax">Fax</Label>
                          <Input
                            value={this.state.editingNode.Customer.Fax}
                            onChange={this.handleFaxChanged}
                            type="text"
                            name="fax"
                            id="fax"
                            placeholder="Fax"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="notes">Other notes</Label>
                          <Input
                            value={this.state.editingNode.Customer.Other}
                            onChange={this.handleOtherChanged}
                            type="textarea"
                            name="notes"
                            id="notes"
                            placeholder="Other notes"
                          />
                        </FormGroup>
                      </Row>
                    </Col>
                  </div>
                </Tab>
                <Tab eventKey={CustomerTab.Contacts} title={"Contacts"}>
                  <div className="full-width full-height customerContacts">
                    <p>
                      Contacts are individuals who can be reached via email to represent the customer. Click a row to edit and click the &quot;+&quot; to add a
                      contact.
                    </p>
                    <div className="customerContactsTable">
                      <DataTable
                        headers={["First name", "Last Name", "Email", "Language"]}
                        headerFlexes={[2, 2, 2, 1]}
                        flowProvider={this.contactFlowProvider}
                        initializeFlowProvider={this.initializeContacts}
                        objectBuilder={this.generateContact}
                        ref={this.contactTable}
                        settingsOverride={Models.genericDataSettings}
                        rowAddRequested={this.addContact}
                      />
                    </div>
                    {this.state.editingContact > -1 && (
                      <Col style={{ padding: "15px" }}>
                        <Row>
                          <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                            <Label for="contactFirstName">First name:</Label>
                            <Input
                              type="text"
                              name="contactFirstName"
                              id="contactFirstName"
                              onChange={this.firstnameChanged}
                              value={this.state.editingNode.Contacts[this.state.editingContact].FirstName}
                            />
                          </FormGroup>
                          <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                            <Label for="contactLastName">Last name:</Label>
                            <Input
                              type="text"
                              name="contactLastName"
                              id="contactLastName"
                              onChange={this.lastnameChanged}
                              value={this.state.editingNode.Contacts[this.state.editingContact].LastName}
                            />
                          </FormGroup>

                          <FormGroup style={{ flex: "1" }}>
                            <Label for="contactLastName">Language:</Label>
                            <Input
                              type="select"
                              name="contactLastName"
                              value={this.state.editingNode.Contacts[this.state.editingContact].Language}
                              placeholder={"Language"}
                              onChange={this.languageChanged}
                            >
                              {Object.keys(Models.LanguageCode)
                                .filter((key) => isNaN(Number(Models.LanguageCode[key as keyof typeof Models.LanguageCode])))
                                .map((it) => (
                                  <option value={it} key={it} data-providerval={it}>
                                    {+it === Models.LanguageCode.Undefined
                                      ? "Preferred Language*"
                                      : Models.LanguageCode[it as keyof typeof Models.LanguageCode]}
                                  </option>
                                ))}
                            </Input>
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="contactEmail">Email:</Label>
                            <Input
                              type="text"
                              name="contactEmail"
                              id="contactEmail"
                              onChange={this.emailChanged}
                              value={this.state.editingNode.Contacts[this.state.editingContact].Email}
                            />
                          </FormGroup>
                        </Row>
                        <Row>
                          <Button onClick={this.deleteContact} outline color="danger">
                            Delete Contact
                          </Button>
                        </Row>
                      </Col>
                    )}
                  </div>
                </Tab>
                <Tab disabled={this.state.editingNode.Customer.TableId <= 0} eventKey={CustomerTab.Subscriptions} title={"Subscriptions"}>
                  <div className="full-width full-height customerSubscriptions">
                    <div className="subscriptionsTable">
                      <p>
                        These are the subscriptions that this customer has. Clicking one will take you to the subscription edit screen and clicking the
                        &quot;+&quot; will take you to the subscription creation screen for this customer.
                      </p>
                      {this.state.editingNode.Customer.TableId > -1 && (
                        <DataTable
                          headers={["Product name", "Start Date", "End Date", "Used Licences", "Maximum Licences"]}
                          headerFlexes={[2, 1, 1, 1, 1]}
                          flowProvider={this.subscriptionFlowProvider}
                          initializeFlowProvider={this.initializeSubscriptions}
                          objectBuilder={this.generateSubscription}
                          ref={this.subscriptionsTable}
                          settingsOverride={settings}
                          rowAddRequested={this.addSubscription}
                        />
                      )}
                      {this.state.editingNode.Customer.TableId === -1 && <h3>Save your new Customer to gain access to the Subscriptions panel.</h3>}
                    </div>
                  </div>
                </Tab>
              </Tabs>
              <FormGroup>
                <Col>
                  <Row>
                    <Button onClick={this.saveRequested} style={{ flex: "1", marginRight: this.props.deleteRequested ? "10px" : "" }} outline color="primary">
                      Save Customer
                    </Button>
                    {this.props.deleteRequested && this.context.canManageSystem() && (
                      <Button onClick={this.deleteRequested} style={{ flex: "1", marginLeft: "10px" }} outline color="danger">
                        Delete Customer
                      </Button>
                    )}
                  </Row>
                </Col>
              </FormGroup>
            </div>
          </div>
        </Loading>
      );
    }
  }
}
