import * as React from "react";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { IndustryType, IUserCreate, LanguageCode, UserStatus } from "src/models/dto/DashboardModels";
import { Convert } from "src/utilities/Helpers";

import { Image } from "../../foundation/Assets";
import { Icon } from "../../foundation/Controls";
import * as Messages from "../../foundation/Messages";
import { AppContext } from "../../state/Contextes";

interface IRegisterFormProps {
  returnToLogin: () => void;
  publisherId: number;
}
interface IRegisterFormState {
  user: IUserCreate;
  createStep: number;
  createFailed: boolean;
  lastError: string;
}

export class RegisterForm extends React.Component<IRegisterFormProps, IRegisterFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IRegisterFormProps) {
    super(props);
    this.state = {
      createStep: 1,
      user: {
        ConfirmPassword: "",
        DateAdded: new Date(),
        DateLastUsed: new Date(),
        DisplayName: "",
        Email: "",
        Industry: IndustryType.Undefined,
        LanguageType: LanguageCode.English,
        LockUntilDate: new Date(),
        Locked: false,
        LoginName: "",
        Occupation: "",
        Password: "",
        PhoneNumber: "",
        SocialAddr: "",
        SocialAddr2: "",
        TableGuid: "00000000-0000-0000-0000-000000000000",
        TableId: 0,
        UserStatus: UserStatus.Pending,
        FirstName: "",
        LastName: "",
        IsSso: false,
        PrivacyConsent: false,
        PromoConsent: true,
      },
      createFailed: false,
      lastError: "",
    };
  }
  // Step 1
  handleUsernameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, Email: arg.target.value, DisplayName: arg.target.value, LoginName: arg.target.value } }));
  };
  handleSsoChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    if (arg.target.checked) {
      this.setState((prevState) => ({ user: { ...prevState.user, Password: "" } }));
      this.setState((prevState) => ({ user: { ...prevState.user, ConfirmPassword: "" } }));
    }
    this.setState((prevState) => ({ user: { ...prevState.user, IsSso: arg.target.checked } }));
  };
  handlePasswordChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, Password: arg.target.value } }));
  };
  handleConfirmPasswordChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, ConfirmPassword: arg.target.value } }));
  };
  handlePromoConsentChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, PromoConsent: arg.target.checked } }));
  };
  handlePrivacyConsentChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, PrivacyConsent: arg.target.checked } }));
  };
  handleFirstNameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, FirstName: arg.target.value } }));
  };
  handleLastNameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, LastName: arg.target.value } }));
  };
  formValid = () => {
    if (this.state.createStep === 1) {
      return (
        ((!Convert.isEmptyOrSpaces(this.state.user.ConfirmPassword) &&
          !Convert.isEmptyOrSpaces(this.state.user.Password) &&
          this.state.user.ConfirmPassword === this.state.user.Password &&
          !Convert.isEmptyOrSpaces(this.state.user.Email)) ||
          this.state.user.IsSso === true) &&
        this.validateEmail(this.state.user.Email)
      );
    } else if (this.state.createStep === 2) {
      return (
        !Convert.isEmptyOrSpaces(this.state.user.FirstName) &&
        !Convert.isEmptyOrSpaces(this.state.user.LastName) &&
        this.state.user.Industry !== 0 &&
        this.state.user.LanguageType !== 0 &&
        this.state.user.PrivacyConsent === true
      );
    }
    return true;
  };
  forwardStep = () => {
    this.setState({ createStep: this.state.createStep + 1 });
  };
  backStep = () => {
    this.setState({ createStep: this.state.createStep - 1 });
  };
  validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  createAccount = async () => {
    let response = await this.context.createUserAccount({
      User: this.state.user,
      Password: this.state.user.Password,
      FirstName: this.state.user.FirstName,
      LastName: this.state.user.LastName,
      PublisherId: this.props.publisherId,
      IsSso: this.state.user.IsSso,
    });
    this.forwardStep();
    if (response.valid()) {
      Messages.Notify.success(`The account was created successfully!`);
      this.setState({ createStep: 3, createFailed: false, lastError: "" });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Account creation failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      this.setState({ createFailed: true, lastError: response.errors[0].Message });
    }
  };

  getFormControls = () => {
    if (this.state.createStep === 1) {
      return (
        <div className="formContainer">
          <h3>Create your account credentials</h3>
          <p>
            <span>
              Please create your account below.{" "}
              <b>Remember that your password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and a minimum of 8 characters.</b>{" "}
            </span>
            <span>Once you have confirmed your account via email, you can log into the proLibro application with your new credentials.</span>
          </p>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="email" name="username" value={this.state.user.LoginName} placeholder={"Email"} onChange={this.handleUsernameChanged} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.lock />} />
              </InputGroupText>
              <Input
                type="password"
                name="password"
                value={this.state.user.Password}
                placeholder={this.context.localization.currentLocale.LoginView.LABEL_LOGIN_PASSWORD}
                onChange={this.handlePasswordChanged}
                disabled={this.state.user.IsSso}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.lock />} />
              </InputGroupText>
              <Input
                type="password"
                name="confirmPassword"
                value={this.state.user.ConfirmPassword}
                placeholder={"Confirm password"}
                onChange={this.handleConfirmPasswordChanged}
                disabled={this.state.user.IsSso}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.lock />} />
              </InputGroupText>
              <Input type="text" name="firstname" value={this.state.user.FirstName} placeholder={"Firstname"} onChange={this.handleFirstNameChanged} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.lock />} />
              </InputGroupText>
              <Input type="text" name="lastname" value={this.state.user.LastName} placeholder={"Lastname"} onChange={this.handleLastNameChanged} />
            </InputGroup>
          </FormGroup>

          <FormGroup switch className="consentSwitch">
            <Input type="switch" role="switch" checked={this.state.user.PromoConsent} onChange={this.handlePromoConsentChanged} />
            <Label check>I consent to receive promotional material, offers, surveys and new feature information from CogniLore Inc.</Label>
          </FormGroup>
          <FormGroup switch className="consentSwitch">
            <Input type="switch" role="switch" checked={this.state.user.PrivacyConsent} onChange={this.handlePrivacyConsentChanged} />
            <Label check>
              I have read and understood the{" "}
              <a href="https://www.prolibro.com/privacy-policy/" target="_">
                proLibro privacy agreement.
              </a>
            </Label>
          </FormGroup>
          {this.context.canManageAccounts() && (
            <FormGroup>
              <InputGroup style={{ paddingLeft: "3em" }}>
                <Label check for="ssoCheckUser">
                  <Input type="checkbox" id="ssoCheckUser" name="ssoCheck" checked={this.state.user.IsSso} onChange={this.handleSsoChanged} />
                  User logs in with SSO provider.
                </Label>
              </InputGroup>
            </FormGroup>
          )}
          <div className="formButtons">
            <Button onClick={this.createAccount} color="primary" outline block disabled={!this.formValid()}>
              Finish
            </Button>
            <Button onClick={this.props.returnToLogin} color="primary" outline block>
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (this.state.createStep === 3) {
      if (this.state.createFailed) {
        return (
          <FormGroup>
            <span>Your account could not be created. Error: {this.state.lastError}, please correct the issue or contact an administrator for assistance.</span>
            <Button onClick={this.backStep} color="primary" outline block>
              Previous
            </Button>
            <Button color="primary" outline block onClick={this.props.returnToLogin}>
              {this.props.publisherId === -1 ? "Dismiss" : "Back to login"}
            </Button>
          </FormGroup>
        );
      } else {
        return (
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {this.props.publisherId === -1
                ? "Account was created and activated. User can now login on Reader/Dashboard."
                : "Your account was created successfully, please check your email for a link to activate it. Then login!"}
            </span>
          </FormGroup>
        );
      }
    } else {
      return <span>Invalid step</span>;
    }
  };

  render() {
    let res = this.getFormControls();
    return <Form className="p-2">{res}</Form>;
  }
}
