import * as React from "react";
import { ModalBody, TabContent, TabPane } from "reactstrap";
import { Single } from "src/collections/Observable";
import { Log } from "src/Logger";
import { AppLoadingSteps, AppSession, LoginType } from "src/models/AppSession";
import { ILoginLibrary, QueryArgType } from "src/models/dto/DashboardModels";

import { Loading, StaticModal } from "../foundation/Controls";
import { DashboardView } from "../foundation/Layout";
import * as Messages from "../foundation/Messages";
import { AppContext } from "../state/Contextes";
import { AccountConfirmView } from "./AccountConfirmView";
import AccountDeletionView from "./AccountDeletionView";
import { AccountManagementView } from "./AccountManagementView/AccountManagementView";
import { AnnouncementView } from "./AnnouncementView/AnnouncementView";
import { BillingView } from "./BillingView/BillingView";
import { BulletinView } from "./BulletinView/BulletinView";
import { ConfirmPasswordResetView } from "./ConfirmPasswordResetView";
import { CustomerView } from "./CustomerView/CustomerView";
import { EmailTemplateView } from "./EmailTemplatesView/EmailTemplateView";
import { FeedbackView } from "./FeedbackView/FeedbackView";
import { FooterView } from "./FooterView";
import { GlobalNoteView } from "./GlobalNoteView/GlobalNotesView";
import { HeaderView } from "./HeaderView";
import { HomeView } from "./HomeView";
import { InviteView } from "./InviteView";
import { LicensingView } from "./LicensingView";
import { LoginView } from "./LoginView/LoginView";
import { MasterPage } from "./MasterPage";
import { ProductView } from "./ProductView/ProductView";
import { ProfileView } from "./ProfileView/ProfileView";
import { PublisherView } from "./PublisherView/PublisherView";
import { ReportingView } from "./ReportingView/ReportingView";
import { SidebarView } from "./SidebarView";
import { StoreFrontView } from "./StoreFrontView/StoreFrontView";
import { SubscriptionView } from "./SubscriptionView/SubscriptionView";
import { SystemView } from "./SystemView/SystemView";
import { TipView } from "./TipView/TipView";
import { TitleView } from "./TitleView/TitleView";

export interface IAppViewState {
  notifications: Messages.INotification[];
  dialog?: Messages.IDialogItemProps;
  initialized: boolean;
  currentView: Single<DashboardView>;
  login: LoginType;
  queryArgType: QueryArgType | null;
  queryArg: string | null;
}
export class AppView extends React.Component<unknown, IAppViewState> {
  context: AppSession;
  static contextType = AppContext;
  static notificationContainer = React.createRef<Messages.NotificationContainer>();
  static dialogContainer = React.createRef<Messages.DialogContainer>();
  constructor(props: any) {
    super(props);
    this.state = {
      notifications: [],
      initialized: false,
      currentView: new Single<DashboardView>(DashboardView.Login),
      login: LoginType.None,
      queryArg: null,
      queryArgType: null,
    };
    this.updateNotifications = this.updateNotifications.bind(this);
    this.updateDialog = this.updateDialog.bind(this);
    this.navigationChanged = this.navigationChanged.bind(this);
  }
  async componentDidMount() {
    this.context.notifications.addListener(this.updateNotifications);
    this.context.dialog.addListener(this.updateDialog);
    this.context.navigateToView.on(this.navigationChanged);
    await this.context.initialize(); // TODO: HAVE INITIALIZE RETURN THE CURRENT VIEW SO WHEN A USER REFRESHES WE CAN GET THEM BACK TO THE VIEW THEY WERE ON.
    const queryString = new URLSearchParams(window.location.search);
    if (queryString.has("invite")) {
      this.setState({ queryArg: queryString.get("invite"), queryArgType: QueryArgType.Invite });
      Log.info("Invite detected with value " + queryString.get("invite"));
      window.history.replaceState({}, document.title, "/");
    }
    if (queryString.has("accountDeletion")) {
      this.setState({ queryArg: queryString.get("accountDeletion"), queryArgType: QueryArgType.AccountDeletion });
      Log.info("AccountDeletion detected with value " + queryString.get("accountDeletion"));
      // window.history.replaceState({}, document.title, "/");
    }
    if (queryString.has("confirmAccount")) {
      this.setState({ queryArg: queryString.get("confirmAccount"), queryArgType: QueryArgType.AccountConfirm });
      Log.info("Account confirm detected with value " + queryString.get("confirmAccount"));
      window.history.replaceState({}, document.title, "/");
    }
    if (queryString.has("resetPassword")) {
      this.setState({ queryArg: queryString.get("resetPassword"), queryArgType: QueryArgType.PasswordReset });
      Log.info("Password reset detected with value " + queryString.get("resetPassword"));
      window.history.replaceState({}, document.title, "/");
    }

    this.setState({ initialized: true, login: this.context.login });
    this.navigationChanged(this.context.login > LoginType.None ? DashboardView.Home : DashboardView.Login);
  }
  componentWillUnmount() {
    this.context.navigateToView.off(this.navigationChanged);
  }
  updateNotifications() {
    this.setState({ notifications: this.context.notifications.rows() });
  }

  updateDialog() {
    this.setState({ dialog: this.context.dialog.get() });
  }

  navigationChanged(newView: DashboardView) {
    this.state.currentView.set(newView);
    this.setState({ login: this.context.login });
  }

  clearInvite = () => {
    this.setState({ queryArg: null, queryArgType: null });
  };

  render() {
    return (
      <AppErrorBoundary>
        <Loading
          isLoading={!this.context.appLoading.isLoaded(AppLoadingSteps.data)}
          theme="opaque"
          className="full-height initial-app-loading"
          status={this.context.localization.currentLocale.Dashboard.LABEL_LOADING_DASHBOARD}
        >
          {this.context.appLoading.isLoaded(AppLoadingSteps.data) && (
            <div id="appLayout" className="d-flex">
              <Messages.NotificationContainer notifications={this.state.notifications} />
              <Messages.DialogContainer current={this.state.dialog} />
              <StaticModal visible={this.context.login === LoginType.None && this.state.queryArg === null} centered={true} backdrop={true}>
                <ModalBody className="p-0">
                  <LoginView vanillaRegistrationRequested={new URLSearchParams(window.location.search).get("registerVanilla") !== null} />
                </ModalBody>
              </StaticModal>
              {this.state.queryArg !== null && this.state.queryArgType === QueryArgType.Invite && (
                <InviteView inviteCode={this.state.queryArg} clearInvite={this.clearInvite} />
              )}
              {this.state.queryArg !== null && this.state.queryArgType === QueryArgType.AccountConfirm && (
                <AccountConfirmView userCode={this.state.queryArg} clearInvite={this.clearInvite} />
              )}
              {this.state.queryArg !== null && this.state.queryArgType === QueryArgType.PasswordReset && (
                <ConfirmPasswordResetView tokenCode={this.state.queryArg} clearInvite={this.clearInvite} />
              )}
              {new URLSearchParams(window.location.search).get("accountDeletion") !== null && <AccountDeletionView />}
              {this.context.login !== LoginType.None && (
                <MasterPage>
                  <HeaderView />
                  <SidebarView currentView={this.state.currentView} />
                  <FooterView />
                  <AppViewTabs activeTab={this.state.currentView} />{" "}
                  {/* This will contain the inner view. Still needs the menu on the left from the mock and the bar at the top. All outside of the AppViewTabs component */}
                </MasterPage>
              )}
            </div>
          )}
        </Loading>
      </AppErrorBoundary>
    );
  }
}
class AppErrorBoundaryProps {
  children?: React.ReactNode;
}
class AppErrorBoundaryState {
  errorCount: number;
}
class AppErrorBoundary extends React.Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: AppErrorBoundaryProps | Readonly<AppErrorBoundaryProps>) {
    super(props);
    this.state = { errorCount: 0 };
  }

  static getDerivedStateFromError() {}

  componentDidCatch(error: Error) {
    Messages.Notify.error(error.message);
    Log.fatal("AN APP LEVEL FRONTEND ERROR OCCURRED. Error message: " + error.message.toString());
    if (error.stack) {
      Log.fatal("Error stacktrace: " + error.stack.toString());
    } else {
      Log.fatal("Error stacktrace: None provided");
    }
    Log.fatal("Error name: " + error.name.toString());

    this.setState({ errorCount: this.state.errorCount + 1 });
  }

  render() {
    return <React.Fragment key={this.state.errorCount}>{this.props.children}</React.Fragment>;
  }
}
interface IAppViewTabsProps {
  activeTab: Single<DashboardView>;
}

interface IAppViewTabsState {
  tab: DashboardView;
  panelList: ILoginLibrary[];
  selectedPublisher: number;
}
class AppViewTabs extends React.Component<IAppViewTabsProps, IAppViewTabsState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IAppViewTabsProps | Readonly<IAppViewTabsProps>) {
    super(props);
    this.handleTabChanged = this.handleTabChanged.bind(this);
  }

  // Function to catch tab changes and rerender.
  handleTabChanged() {
    this.setState({ tab: this.props.activeTab.get() });
  }

  componentWillUnmount() {
    this.props.activeTab.removeListener(this.handleTabChanged);
  }

  componentDidMount() {
    this.props.activeTab.addListener(this.handleTabChanged);
  }

  render() {
    let isLoggedIn = this.context.login > LoginType.None;
    return (
      <div className="bookview-tabs">
        <TabContent activeTab={this.props.activeTab.get()}>
          <TabPane tabId={DashboardView.Login}>{""}</TabPane>
          <TabPane tabId={DashboardView.Home}>
            <HomeView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Profile}>
            <ProfileView />
          </TabPane>
          <TabPane tabId={DashboardView.System}>
            <SystemView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Billing}>
            <BillingView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.EmailTemplates}>
            <EmailTemplateView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Publishers}>
            <PublisherView />
          </TabPane>
          <TabPane tabId={DashboardView.Subscriptions}>
            <SubscriptionView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Licensing}>
            <LicensingView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Products}>
            <ProductView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Titles}>
            <TitleView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Customers}>
            <CustomerView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Reporting}>
            <ReportingView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.AccountManagement}>
            <AccountManagementView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Feedback}>
            <FeedbackView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Bulletins}>
            <BulletinView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Tips}>
            <TipView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.GlobalNotes}>
            <GlobalNoteView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.Announcements}>
            <AnnouncementView IsLoggedIn={isLoggedIn} />
          </TabPane>
          <TabPane tabId={DashboardView.StoreFront}>
            <StoreFrontView IsLoggedIn={isLoggedIn} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
