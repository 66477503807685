import * as React from 'react';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { DashboardView } from 'src/ui/foundation/Layout';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import { Drawer, DrawerContainer } from '../../foundation/Controls';
import { DataItem, DataRow, DataTable } from '../../foundation/DataTable';
import * as Messages from '../../foundation/Messages';
import { Action, INode, IRequest, IResponse } from '../../foundation/StandaloneCogniflow';
import { AppContext } from '../../state/Contextes';
import { EmailTemplateForm } from './EmailTemplateForm';

export interface IEmailTemplateViewProps {
  IsLoggedIn: boolean;
}
export interface IEmailTemplateViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
}

export class EmailTemplateView extends React.Component<IEmailTemplateViewProps, IEmailTemplateViewState> {
  context: AppSession;
  static contextType = AppContext;
  templateTable = React.createRef<DataTable>();
  constructor(props: IEmailTemplateViewProps) {
    super(props);
    this.state = { drawerShow: false, currentDrawerContent: null };
  }
  loginInit = () => {
    this.context.viewedViews.get(DashboardView.EmailTemplates)!.progressLoading();
  };
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.EmailTemplates)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.EmailTemplates)!.loading.off(this.loginInit);
  }
  /* #region Permissions */
  private initializeTemplates = (): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowEmailTemplates({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0 },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private templateFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowEmailTemplates({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private templateRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <EmailTemplateForm initialNode={e as Models.IEmailTemplateViewModel} saveRequested={this.saveTemplate} deleteRequested={this.deleteTemplate} />
      ),
    });
  };
  private templateInsertRequest = () => {
    let blankModel: Models.IEmailTemplateViewModel = {
      EmailTemplate: {
        CreatedByUserId: -1,
        CreatedDate: new Date(Date.now()),
        EmailContents: "",
        PublisherId: -1,
        Subject: "",
        TemplateName: "",
        TemplateType: 0,
        TableId: 0,
      },
      Publisher: null,
      Index: -1,
      IsFirst: false,
      IsLast: false,
    };

    this.setState({ drawerShow: true, currentDrawerContent: <EmailTemplateForm initialNode={blankModel} saveRequested={this.saveTemplate} /> });
  };
  private reloadTemplatesAndDismiss = () => {
    this.templateTable.current!.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };
  private saveTemplate = async (e: Models.IEmailTemplateViewModel) => {
    if (e.EmailTemplate.TemplateType === Models.EmailTemplateType.SystemMessage) {
      e.EmailTemplate.Subject = "${SYSTEM_SUBJECT}";
    }
    let response = await this.context.insertOrUpdateEmailTemplate({ Template: e.EmailTemplate });
    if (response.valid()) {
      Messages.Notify.success(`The Email Template item was saved successfully!`);
      this.reloadTemplatesAndDismiss();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  private deleteTemplate = async (e: Models.IEmailTemplateViewModel) => {
    let result = await Messages.Dialog.confirm(
      `Are you sure you wish to delete this Template? Emails for it will no longer be sent using it and will fall back to the default.`
    );
    if (result === "true") {
      let response = await this.context.deleteEmailTemplate({ Template: e.EmailTemplate });
      if (response.valid()) {
        Messages.Notify.success(`The Permission item was deleted successfully!`);
        this.reloadTemplatesAndDismiss();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private generateEmailTemplateRow = (node: INode): JSX.Element => {
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.EmailTemplate.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder" key={1} value={node.EmailTemplate.TemplateName} />);
    dataItems.push(
      <DataItem flexVal={1} className="rightBorder" key={2} value={Convert.dateToFormattedString(node.EmailTemplate.CreatedDate as Date, Languages.English)} />
    );
    if (node.EmailTemplate.PublisherId === null) {
      dataItems.push(<DataItem flexVal={2} className="centerText bolded rightBorder" key={3} value={"System"} />);
    } else {
      dataItems.push(<DataItem flexVal={2} className="rightBorder" key={3} value={node.Publisher.Name as string} />);
    }
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={5} value={Models.EmailTemplateType[node.EmailTemplate.TemplateType]} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.templateRowEditRequest} />;
  };
  /* #endregion */
  render() {
    if (
      !this.props.IsLoggedIn ||
      this.context.getManageablePublishers().length === 0 ||
      !this.context.viewedViews.get(DashboardView.EmailTemplates)!.isLoaded()
    ) {
      return "";
    }
    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.batchSize = 25;
    return (
      <div className="mainView">
        <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
          <Drawer
            onBackdropClicked={() => {
              this.setState({ drawerShow: false, currentDrawerContent: null });
            }}
            isOpen={this.state.drawerShow}
            backdrop={true}
            className="details-view"
          >
            {this.state.currentDrawerContent}
          </Drawer>
          <div className="emailTemplateView">
            <div className="section">
              <h1>Welcome to the Email Template View</h1>
              <p>
                Welcome to the email template view. This view is used by system admins and publisher managers to create and edit the emails that Connect may
                send their users.
              </p>
              <p>
                If you are seeing this page, it&apos;s because you are listed as an administrator of the Dashboard system for proLibro Connect or you manage one
                or many publishers.
              </p>
              <p>
                In this view you can create new email templates, edit existing ones to which you have the rights. You may not see any. If this is the case the
                Connect system will use proLibro branded email templates for the various template types.
              </p>
            </div>
            <div className="section">
              <h2>Current Templates</h2>
              <p>These are the email templates that currently exist on the system. </p>
              <DataTable
                headers={["Template name", "Creation Date", "Publisher", "Type"]}
                headerFlexes={[2, 1, 2, 1]}
                rowAddRequested={this.templateInsertRequest}
                flowProvider={this.templateFlowProvider}
                initializeFlowProvider={this.initializeTemplates}
                objectBuilder={this.generateEmailTemplateRow}
                ref={this.templateTable}
                settingsOverride={settings}
              />
            </div>
            <div className="bottomSpacer" />
          </div>
        </DrawerContainer>
      </div>
    );
  }
}
