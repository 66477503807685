import { formatDistanceToNow } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Languages } from "src/localization/Locale";
import { Loading } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";

const StoreStats: React.FC<IAdminStoreStatsProps> = (props) => {
  const context = useContext(AppContext);
  const [panelDisabled, setPanelDisabled] = useState(false);

  const [salesFeed, setSalesFeed] = useState<Models.IStoreListingSaleFeedViewModel[]>([]);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [float, setFloat] = useState<number>(0);

  const storeListingStatsTable = useRef<DataTable>(null);
  const affiliateStatsTable = useRef<DataTable>(null);
  const spotLightListingTable = useRef<DataTable>(null);

  let pubId = context.canManageSystem() ? -1 : props.currentPub!.PublisherId;

  // Your component logic here
  useEffect(() => {
    let fetchMyAPI = async () => {
      await context.getTotalSales({ PublisherId: pubId }).then((result) => {
        setTotalSales(result.data.TotalSales);
      });
      updateSalesAndCreditsData();
      setInterval(() => {
        updateSalesAndCreditsData();
      }, 10000);
    };
    fetchMyAPI();
  }, []);

  const updateSalesAndCreditsData = async () => {
    await context.fetchSalesAndCreditsData({ PublisherId: pubId }).then((result) => {
      if (context.canManageSystem()) {
        setFloat(result.data.Float ? result.data.Float : 0);
      }
      setSalesFeed(result.data.SalesFeed);
    });
  };
  const initializeStoreListingStats = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      setPanelDisabled(true);
      let result = await context.flowStoreListingPerformance({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: pubId,
      });
      setPanelDisabled(false);
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  const storeListingStatFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      setPanelDisabled(true);
      let result = await context.flowStoreListingPerformance({ FlowRequest: request.Batches[0], PublisherId: pubId });
      setPanelDisabled(false);
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  const generateStoreListingStat = (n: INode) => {
    let node = n as Models.IStoreListingPerformanceViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} className="centerText" key={1} value={node.StoreListing} />);

    dataItems.push(<DataItem flexVal={1} key={2} className="centerText" value={Convert.toUSDFromStoreCredits(node.TotalSales, true)} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} />;
  };

  const initializeAffiliateStats = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      setPanelDisabled(true);
      let result = await context.flowAffiliateCodePerformance({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: pubId,
      });
      setPanelDisabled(false);
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  const affiliateStatFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      setPanelDisabled(true);
      let result = await context.flowAffiliateCodePerformance({ FlowRequest: request.Batches[0], PublisherId: pubId });
      setPanelDisabled(false);
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  const generateAffiliateStat = (n: INode) => {
    let node = n as Models.IAffiliateCodePerformanceViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} className="centerText" key={1} value={node.AffiliateCode} />);

    dataItems.push(<DataItem flexVal={1} key={2} className="centerText" value={Convert.toUSDFromStoreCredits(node.TotalSales, true)} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} />;
  };

  const initializeSpotlightListings = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      setPanelDisabled(true);
      let result = await context.flowSpotlightListings({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: -1,
      });
      setPanelDisabled(false);
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  const spotlightListingFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      setPanelDisabled(true);
      let result = await context.flowSpotlightListings({
        FlowRequest: request.Batches[0],
        PublisherId: -1,
      });
      setPanelDisabled(false);
      if (result.valid()) {
        resolve({
          Batches: [Convert.indexify(result.data.FlowResponse)],
        });
      } else {
        reject();
      }
    });

  const generateStoreListing = (n: INode) => {
    let node = n as Models.IStoreListingViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.StoreListing.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} key={1} className="centerText" value={node.StoreListing.Name} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="centerText" value={node.StoreListing.Description} />);
    dataItems.push(
      <DataItem flexVal={1} key={3} className="centerText" value={Convert.dateToFormattedString(node.StoreListing.StartDate, Languages.English)} />
    );
    if (new Date(node.StoreListing.EndDate) < new Date()) {
      dataItems.push(
        <DataItem flexVal={1} key={4} className="centerText" value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.StoreListing.EndDate, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(
        <DataItem flexVal={1} key={5} className="centerText" value={Convert.dateToFormattedString(node.StoreListing.EndDate, Languages.English)} />
      );
    }

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} />;
  };

  const convertUTCToLocalTime = (date: Date) => {
    let parts = date.toString().split(/[-T:.]/);
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2], +parts[3], +parts[4], +parts[5]));
    return dato;
  };
  let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
  settings.maxHeight = "500px";
  return (
    <div className="section">
      <Loading isLoading={panelDisabled}>
        <h1>{pubId === -1 ? "System-wide" : props.currentPub?.DisplayName} Store Sales Overview</h1>
        <div>Total Sales: {Convert.toUSDFromStoreCredits(totalSales, true)}</div>
        {context.canManageSystem() && <div>Float: {Convert.toUSDFromStoreCredits(float, true)}</div>}

        <h2>Recent Purchases</h2>
        <div className="salesFeedList">
          <div className="recentPurchasesHeader">
            <span className="listingHeader">Listing</span>
            <span className="creditsHeader">Price</span>
            <span className="purchasedHeader">Purchased</span>
          </div>
          {salesFeed.map((sale, index) => (
            <div key={index} className="saleItem">
              <span className="listingName">{sale.Listing}</span>
              <span className="credits">{Convert.toUSDFromStoreCredits(sale.Credits, true)}</span>
              <span className="timeAgo">{formatDistanceToNow(convertUTCToLocalTime(sale.PurchaseDate), { addSuffix: true, includeSeconds: true })}</span>
            </div>
          ))}
        </div>
        <h2>Store Listings Performance This Month</h2>
        <p>This table shows the performance of your listings in sales. Which listings are generating the most revenue.</p>
        <DataTable
          headers={["Store Listing", "Sales"]}
          headerFlexes={[2, 1]}
          flowProvider={storeListingStatFlowProvider}
          initializeFlowProvider={initializeStoreListingStats}
          objectBuilder={generateStoreListingStat}
          ref={storeListingStatsTable}
          settingsOverride={settings}
        />
        <h2>Affiliate Codes Performance This Month</h2>
        <p>This table shows the performance of your affiliate codes in sales. Which affiliate codes are generating the most revenue.</p>
        <DataTable
          headers={["Affiliate Code", "Sales"]}
          headerFlexes={[2, 1]}
          flowProvider={affiliateStatFlowProvider}
          initializeFlowProvider={initializeAffiliateStats}
          objectBuilder={generateAffiliateStat}
          ref={affiliateStatsTable}
          settingsOverride={settings}
        />
        {context.canManageSystem() && (
          <React.Fragment>
            <h2>Spotlight Listings</h2>
            <DataTable
              headers={["Store Listing Name", "Description", "Start Date", "End Date"]}
              headerFlexes={[2, 2, 1, 1]}
              flowProvider={spotlightListingFlowProvider}
              initializeFlowProvider={initializeSpotlightListings}
              objectBuilder={generateStoreListing}
              ref={spotLightListingTable}
              settingsOverride={settings}
            />
          </React.Fragment>
        )}
      </Loading>
    </div>
  );
};
export interface IAdminStoreStatsProps {
  currentPub: Models.ILoginLibrary | null;
}

export default StoreStats;
