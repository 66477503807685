import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { IRoyaltyTermViewModel } from "src/models/dto/DashboardModels";
import { Convert } from "src/utilities/Helpers";

interface IRoyaltyTermFormProps {
  // Define your props here
  initialNode: IRoyaltyTermViewModel;
  saveRequested: (node: IRoyaltyTermViewModel) => void;
  deleteRequested?: (node: IRoyaltyTermViewModel) => void;
}

const RoyaltyTermForm: React.FC<IRoyaltyTermFormProps> = (props) => {
  // Define your state variables here using useState hook
  const [editingNode, setCurrentNode] = useState(props.initialNode);

  // Define your event handlers or other functions here
  const formValid = () => editingNode.PublisherId > 0 && editingNode.NewRoyalty > 0.05 && editingNode.NewRoyalty < 1;
  const publisherIdChanged = (e: React.ChangeEvent<HTMLInputElement>) => setCurrentNode({ ...editingNode, PublisherId: parseInt(e.target.value) });
  const startDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => setCurrentNode({ ...editingNode, StartDate: new Date(e.target.value) });
  const endDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => setCurrentNode({ ...editingNode, EndDate: new Date(e.target.value) });
  const royaltyChanged = (e: React.ChangeEvent<HTMLInputElement>) => setCurrentNode({ ...editingNode, NewRoyalty: parseFloat(e.target.value) });

  let startDate = Convert.formatDateForForm(new Date(editingNode.StartDate));
  let endDate = Convert.formatDateForForm(new Date(editingNode.EndDate));
  return (
    <div className="formContainer">
      <h2>Royalty Term Management</h2>
      {props.deleteRequested ? (
        <p>You have selected a Royalty Term to consult or edit.</p>
      ) : (
        <p>
          You have chosen to create a new Royalty Term. Purchases made within the terms will be ajusted. Transactions already paid out will not be
          re-calculated. Enter the properties below and click &quot;save&quot;
        </p>
      )}
      <Form>
        <Col>
          <Row>
            <FormGroup style={{ flex: "1" }}>
              <Label for="configName">Publisher ID</Label>
              <Input
                invalid={editingNode.PublisherId === 0}
                value={editingNode.PublisherId}
                onChange={publisherIdChanged}
                type="number"
                name="publisherId"
                id="publisherId"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup style={{ flex: "1", marginRight: "15px" }}>
              <Label for="startDate">Starting date</Label>
              <Input type="date" name="startDate" id="startDate" value={startDate} onChange={startDateChanged} />
            </FormGroup>
            <FormGroup style={{ flex: "1" }}>
              <Label for="endDate">Ending date</Label>
              <Input type="date" name="endDate" id="endDate" value={endDate} onChange={endDateChanged} />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup style={{ flex: "1" }}>
              <Label for="configName">
                New Royalty = {editingNode.NewRoyalty * 100 + "%"}. For purchases made between the dates, which haven`&apos;`t been paid out yet, will be
                calculated with a royalty take of {editingNode.NewRoyalty * 100 + "%"} for CogniLore.
              </Label>
              <Input
                invalid={editingNode.NewRoyalty < 0 || editingNode.NewRoyalty > 1}
                value={editingNode.NewRoyalty}
                onChange={royaltyChanged}
                type="number"
                name="newRoyalty"
                id="newRoyalty"
              />
            </FormGroup>
          </Row>
        </Col>
        <Col>
          <Row className="formButtons">
            {props.deleteRequested && (
              <Button disabled={!formValid()} onClick={() => props.deleteRequested!(editingNode)} color="danger" outline>
                Delete
              </Button>
            )}
            <Button disabled={!formValid()} onClick={() => props.saveRequested!(editingNode)} color="info" outline>
              Save
            </Button>
          </Row>
        </Col>
      </Form>
    </div>
  );
};

export default RoyaltyTermForm;
