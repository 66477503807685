import * as React from 'react';
import { Button } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { ILoginLibrary } from 'src/models/dto/DashboardModels';
import { Drawer, DrawerContainer } from 'src/ui/foundation/Controls';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { DashboardView } from 'src/ui/foundation/Layout';
import { Action, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';
import { PanelView } from '../PanelView';
import { EditPublicationPackDefinitionForm } from './EditPublicationPackDefinitionForm';
import { EditPublicationPackForm } from './EditPublicationPackForm';

export interface IBillingViewProps {
  IsLoggedIn: boolean;
}
export interface IBillingViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  allowedPubs: ILoginLibrary[];
  currentPub: ILoginLibrary | null;
  panelDisabled: boolean;
}
export class BillingView extends React.Component<IBillingViewProps, IBillingViewState> {
  context: AppSession;
  static contextType = AppContext;
  publicationPackTable = React.createRef<DataTable>();
  publicationPackDefinitionTable = React.createRef<DataTable>();
  mainViewRef = React.createRef<HTMLDivElement>();
  constructor(props: IBillingViewProps) {
    super(props);
    this.state = { allowedPubs: [], currentDrawerContent: null, currentPub: null, drawerShow: false, panelDisabled: false };
  }
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Billing)!.loading.on(this.initialize);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Billing)!.loading.off(this.initialize);
  }
  initialize = () => {
    let allowedPubs = this.context.getManageablePublishers();
    if (allowedPubs.length > 0) {
      this.setState({ allowedPubs: allowedPubs, currentPub: allowedPubs[0] });
    }
    this.context.viewedViews.get(DashboardView.Billing)!.progressLoading();
  };

  publisherChanged = (newPub: ILoginLibrary) => {
    this.setState(
      { currentPub: this.state.allowedPubs.find((x) => x.PublisherId === newPub.PublisherId)!, currentDrawerContent: null, drawerShow: false },
      () => {
        this.publicationPackTable.current!.reload();
      }
    );
  };
  // #region Packs
  private initializePublicationPacks = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowPublicationPacks({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0,
          });
        } else {
          reject();
        }
      });
    });
  private publicationPackFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowPublicationPacks({ FlowRequest: request.Batches[0], PublisherId: this.state.currentPub!.PublisherId });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        } else {
          reject();
        }
      });
    });
  generatePublicationPack = (n: INode) => {
    let node = n as Models.IPublicationPackViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.PublicationPack.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(
      <DataItem
        flexVal={1}
        key={1}
        className="centerText"
        value={!Convert.isEmptyOrSpaces(node.PublicationPack.PackName) ? node.PublicationPack.PackName : node.PublicationPackDefinition.PackDefName}
      />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={2}
        className="rightBorder leftBorder centerText"
        value={Convert.dateToFormattedString(node.PublicationPack.LastBilled, Languages.English)}
      />
    );
    dataItems.push(
      <DataItem flexVal={1} key={3} className="centerText" value={Convert.dateToFormattedString(node.PublicationPack.NextBilling, Languages.English)} />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={4}
        className="rightBorder leftBorder centerText"
        value={
          (Math.round(node.CalculatedPublicationsCost * 100) / 100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + Convert.getCurrencySymbol(node.PublicationPackDefinition.BillingCurrency)
        }
      />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={5}
        className="rightBorder leftBorder centerText"
        value={
          (Math.round(node.CalculatedUserLicensingCost * 100) / 100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + Convert.getCurrencySymbol(node.PublicationPackDefinition.BillingCurrency)
        }
      />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={6}
        className="rightBorder leftBorder centerText"
        value={
          (Math.round((node.CalculatedPublicationsCost + node.CalculatedUserLicensingCost) * 100) / 100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + Convert.getCurrencySymbol(node.PublicationPackDefinition.BillingCurrency)
        }
      />
    );
    dataItems.push(<DataItem flexVal={1} key={7} className="rightBorder leftBorder centerText" value={node.PublicationPack.RemainingSlots.toString()} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.publicationPackEdit} />;
  };
  publicationPackEdit = (n: INode) => {
    let node = n as Models.IPublicationPackViewModel;
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <EditPublicationPackForm
          initialNode={node}
          dismissDrawer={() => this.setState({ drawerShow: false, currentDrawerContent: null }, () => this.publicationPackTable.current!.reload())}
          createMode={false}
          reloadPublicationPacks={() => this.publicationPackTable.current!.reload()}
        />
      ),
    });
  };
  createPublicationPack = () => {
    let blankModel: Models.IPublicationPackViewModel = {
      BackListSlotsInUse: 0,
      BackListSubtotal: 0,
      CalculatedPublicationsCost: 0,
      CalculatedUserLicensingCost: 0,
      EmptySlotCostPerSlot: 0,
      FrontListSlotsInUse: 0,
      FrontListSubtotal: 0,
      Index: -1,
      IsFirst: false,
      IsLast: false,
      LicensesForInterval: 0,
      MaintenanceSlotsInUse: 0,
      MaintenanceSubtotal: 0,
      PublicationPack: {
        BackListPricePerAnnum: 0,
        DateCreated: new Date(),
        FrontListPricePerAnnum: 0,
        LastBilled: new Date(),
        MaintenancePricePerAnnum: 0,
        NextBilling: new Date(),
        PublicationPackDefinitionId: -1,
        PublisherId: this.state.currentPub!.PublisherId,
        RemainingSlots: 0,
        Slots: 0,
        TableId: 0,
        PackDescription: "",
        PackName: "",
        TableGuid: "00000000-0000-0000-0000-000000000000",
      },
      PublicationPackDefinition: {
        BillingCurrency: Models.CurrencyCodes.CAD,
        PackDefDescription: "",
        PackDefName: "Not set",
        PublicationPackInvoicing: Models.PublicationPackInvoicing.PerPublication,
        TableId: 0,
        UserLicenseInvoicing: Models.UserLicenseInvoicing.RecurringPerAnnum,
      },
      UserLicenseRanges: [],
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <EditPublicationPackForm
          initialNode={blankModel}
          dismissDrawer={() => this.setState({ drawerShow: false, currentDrawerContent: null }, () => this.publicationPackTable.current!.reload())}
          createMode={true}
          reloadPublicationPacks={() => this.publicationPackTable.current!.reload()}
        />
      ),
    });
  };

  // #endregion
  // #region Pack definitions
  private initializePublicationPackDefinitions = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowPublicationPackDefinitions({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private publicationPackDefinitionFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowPublicationPackDefinitions({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  firendlyUserLicenseInvoicing(val: Models.UserLicenseInvoicing) {
    switch (val) {
      case Models.UserLicenseInvoicing.RecurringPerAnnum:
        return "Per license Recurring";
      case Models.UserLicenseInvoicing.OneTimeInvoice:
        return "Charged at creation";
      default:
        return Models.UserLicenseInvoicing[val];
    }
  }
  friendlyPublicationPackInvoicing(val: Models.PublicationPackInvoicing) {
    switch (val) {
      case Models.PublicationPackInvoicing.FlatPerAnnum:
        return "Flat rate per pack";
      case Models.PublicationPackInvoicing.PerPublication:
        return "Per publication recurring";
      default:
        return Models.PublicationPackInvoicing[val];
    }
  }

  generatePublicationPackDefinition = (n: INode) => {
    let node = n as Models.IPublicationPackDefinitionViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.PublicationPackDefinition.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={1} key={1} className="centerText" value={node.PublicationPackDefinition.PackDefName} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="centerText" value={node.PublicationPackDefinition.PackDefDescription} />);
    dataItems.push(
      <DataItem flexVal={1} key={3} className="centerText" value={this.firendlyUserLicenseInvoicing(node.PublicationPackDefinition.UserLicenseInvoicing)} />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={4}
        className="centerText"
        value={this.friendlyPublicationPackInvoicing(node.PublicationPackDefinition.PublicationPackInvoicing)}
      />
    );
    dataItems.push(<DataItem flexVal={1} key={5} className="centerText" value={Models.CurrencyCodes[node.PublicationPackDefinition.BillingCurrency]} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.publicationPackDefinitionEdit} />;
  };
  publicationPackDefinitionEdit = (n: INode) => {
    let node = n as Models.IPublicationPackDefinitionViewModel;
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <EditPublicationPackDefinitionForm
          initialNode={node}
          dismissDrawer={() => this.setState({ drawerShow: false, currentDrawerContent: null }, () => this.publicationPackTable.current!.reload())}
          createMode={false}
          reloadPublicationPackDefinitions={() => this.publicationPackDefinitionTable.current!.reload()}
        />
      ),
    });
  };
  createPublicationPackDefinition = () => {
    let blankModel: Models.IPublicationPackDefinitionViewModel = {
      Index: -1,
      IsFirst: false,
      IsLast: false,
      PublicationPacks: [],
      PublicationPackDefinition: {
        BillingCurrency: Models.CurrencyCodes.CAD,
        PackDefDescription: "",
        PackDefName: "",
        PublicationPackInvoicing: Models.PublicationPackInvoicing.PerPublication,
        TableId: 0,
        UserLicenseInvoicing: Models.UserLicenseInvoicing.RecurringPerAnnum,
      },
      UserLicenseRanges: [],
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <EditPublicationPackDefinitionForm
          initialNode={blankModel}
          dismissDrawer={() => this.setState({ drawerShow: false, currentDrawerContent: null }, () => this.publicationPackTable.current!.reload())}
          createMode={true}
          reloadPublicationPackDefinitions={() => {
            this.publicationPackDefinitionTable.current!.reload();
          }}
        />
      ),
    });
  };
  // #endregion
  redeemPublicationPack = async () => {
    let result = await Messages.Dialog.confirm(
      "You are going to redeem a publication pack for the publisher " +
        this.state.currentPub!.DisplayName +
        ". This cannot be undone. Do you wish to continue?",
      `Assign Publication Pack`
    );
    if (result === "true") {
      let f = document.createElement("input");
      f.style.display = "none";
      f.type = "file";
      f.name = "file";
      f.accept = ".cogniPack";
      f.onchange = this.innerRedeemPublicationPack;
      this.mainViewRef.current!.appendChild(f);
      f.click();
    }
  };
  innerRedeemPublicationPack = (e: Event) => {
    let inp = e.target as HTMLInputElement;
    let reader = new FileReader();
    reader.onload = async () => {
      const dataReplace = /data: ?.*; ?base64. ?/g;
      let rawData = atob((reader.result! as string).replace(dataReplace, ""));
      let response = await this.context.executePublicationPackToken({ PublisherId: this.state.currentPub!.PublisherId, TokenBytes: rawData });
      if (response.valid()) {
        Messages.Notify.success("Publication pack redeemed successfully!");
        this.publicationPackTable.current!.reload();
        this.publicationPackDefinitionTable.current!.reload();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    };
    reader.readAsDataURL(inp.files![0]!);
  };
  render() {
    if (!this.props.IsLoggedIn || this.state.allowedPubs.length <= 0 || !this.context.viewedViews.get(DashboardView.Billing)!.isLoaded()) {
      return "";
    }
    return (
      <div ref={this.mainViewRef} className="mainView" style={{ display: "flex" }}>
        <PanelView
          publisherList={this.state.allowedPubs}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.currentPub!}
          disabled={this.state.panelDisabled}
        />
        <div className="billingView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="billingViewInner">
              <div className="section">
                <h1>Welcome to the Billing View</h1>
                <p>
                  Welcome to the Billing View. This view is used to view your Publisher&apos;s current publication packs and the current license invoice tally.
                </p>
                <p>
                  Below you can view the current packs for your publisher, their prices and the billing dates for each. Clicking on one will open the details
                  view with the terms for the pack that is clicked. User License prices are calculated based on the creation date of licenses within the current
                  billing interval. Click a pack for more details.
                </p>
                <p>If you are seeing this page, it&apos;s because you are listed as a system administrator or a publisher manager.</p>
              </div>
              <div className="section">
                <h3>Your Publication Packs</h3>
                <Button style={{ width: "fit-content", marginBottom: "15px" }} outline color="info" onClick={this.redeemPublicationPack}>
                  Redeem publication pack
                </Button>
                <DataTable
                  headers={["Pack name", "Last billed", "Next Billing", "Current pack price", "User Licensing Accrued", "Current Subtotal", "Slots remaining"]}
                  headerFlexes={[1, 1, 1, 1, 1, 1, 1]}
                  flowProvider={this.publicationPackFlowProvider}
                  initializeFlowProvider={this.initializePublicationPacks}
                  objectBuilder={this.generatePublicationPack}
                  ref={this.publicationPackTable}
                  settingsOverride={Models.genericDataSettings}
                  rowAddRequested={this.context.canManageSystem() ? this.createPublicationPack : undefined}
                />
              </div>
              {this.context.canManageSystem() && (
                <div className="section">
                  <h2>Publication Pack definitions (admin only)</h2>
                  <p>
                    As an admin you can transfer Publication Packs to other publication pack definitions. This can be useful when you want to create new terms
                    and user licensing ranges specific to a pack without affecting other publishers with packs in the same definition.
                  </p>
                  <p>
                    To change the publication pack definition of the current pack, click on the new pack to assign it to and click &quot;save publication
                    pack&quot; at the bottom of this window. The new publication pack definition will be used to calculate billing for this term.
                  </p>
                  <DataTable
                    headers={["Definition name", "Definition description", "User license invoicing type", "Pack invoicing type", "Billing currency"]}
                    headerFlexes={[1, 2, 1, 1, 1]}
                    flowProvider={this.publicationPackDefinitionFlowProvider}
                    initializeFlowProvider={this.initializePublicationPackDefinitions}
                    objectBuilder={this.generatePublicationPackDefinition}
                    ref={this.publicationPackDefinitionTable}
                    settingsOverride={Models.genericDataSettings}
                    rowAddRequested={this.context.canManageSystem() ? this.createPublicationPackDefinition : undefined}
                  />
                </div>
              )}
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
