import * as React from 'react';
import { Button } from 'reactstrap';
import { AppSession } from 'src/models/AppSession';
import { ILoginLibrary } from 'src/models/dto/DashboardModels';
import { Drawer, DrawerContainer } from 'src/ui/foundation/Controls';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { DashboardView } from 'src/ui/foundation/Layout';
import { Action, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';
import { PanelView } from '../PanelView';
import { CustomerForm } from './CustomerForm';

export interface ICustomerViewProps {
  IsLoggedIn: boolean;
}
export interface ICustomerViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  allowedPubs: ILoginLibrary[];
  currentPub: ILoginLibrary | null;
  panelDisabled: boolean;
}
export class CustomerView extends React.Component<ICustomerViewProps, ICustomerViewState> {
  context: AppSession;
  static contextType = AppContext;
  customerTable = React.createRef<DataTable>();
  constructor(props: ICustomerViewProps) {
    super(props);
    this.state = { allowedPubs: [], currentDrawerContent: null, currentPub: null, drawerShow: false, panelDisabled: false };
  }
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Customers)!.loading.on(this.initialize);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Customers)!.loading.off(this.initialize);
  }
  initialize = () => {
    let allowedPubs = this.context.getManageableSubscriptions();
    if (allowedPubs.length > 0) {
      this.setState({ allowedPubs: allowedPubs, currentPub: allowedPubs[0] });
    }
    this.context.viewedViews.get(DashboardView.Customers)!.progressLoading();
  };

  publisherChanged = (e: ILoginLibrary) => {
    this.setState({ currentPub: this.state.allowedPubs.find((x) => x.PublisherId === e.PublisherId)! }, () => {
      this.customerTable.current!.reload();
    });
  };

  private initializeCustomer = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowCustomers({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });

        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0,
          });
        } else {
          reject();
        }
      });
    });
  private customerFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowCustomers({ FlowRequest: request.Batches[0], PublisherId: this.state.currentPub!.PublisherId });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        } else {
          reject();
        }
      });
    });
  saveCustomer = async (e: Models.ICustomerViewModel) => {
    let response = await this.context.insertOrUpdateCustomer({ Customer: e.Customer, Contacts: e.Contacts });
    if (response.valid()) {
      Messages.Notify.success(`The customer was saved successfully!`);
      this.setState(
        {
          drawerShow: true,
          currentDrawerContent: (
            <CustomerForm
              initialNode={response.data.Customer}
              saveRequested={this.saveCustomer}
              deleteRequested={this.deleteCustomer}
              reloadCustomers={this.reloadCustomers}
            />
          ),
        },
        () => {
          this.customerTable.current!.reload();
        }
      );
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  deleteCustomer = async (e: Models.ICustomerViewModel) => {
    let dResp = await Messages.Dialog.confirm(
      <div>
        <span>Are you absolutely sure you wish to delete this customer? Doing so will:</span>
        <ul>
          <li>Delete their subscriptions.</li>
          <li>Delete ALL Licences related to their subscriptions.</li>
          <li>Clear their contacts.</li>
          <li>Users on their subscriptions will no longer have access to the titles afforded to them by those subscriptions.</li>
        </ul>
      </div>,
      "Delete customer?",
      Messages.Dialog.Buttons.DeleteCancel
    );
    if (dResp === "true") {
      let response = await this.context.deleteCustomer({ Customer: e.Customer });
      if (response.valid()) {
        Messages.Notify.success("Customer deleted successfully!");
        this.customerTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Delete failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  reloadCustomers = () => {
    this.customerTable.current!.reload();
  };
  addCustomerRequested = () => {
    let blank: Models.ICustomerViewModel = {
      Contacts: [],
      Customer: {
        Address1: "",
        Address2: "",
        City: "",
        Country: "",
        Fax: "",
        CustomerNumber: "",
        Name: "",
        Other: "",
        Phone: "",
        PostalCode: "",
        Province: "",
        PublisherId: this.state.currentPub!.PublisherId,
        TableId: 0,
        Web: "",
      },
      Index: 0,
      IsFirst: false,
      IsLast: false,
      SubscriptionCount: 0,
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: <CustomerForm initialNode={blank} saveRequested={this.saveCustomer} reloadCustomers={this.reloadCustomers} />,
    });
  };
  customerEditRequested = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <CustomerForm
          initialNode={e as Models.ICustomerViewModel}
          saveRequested={this.saveCustomer}
          deleteRequested={this.deleteCustomer}
          reloadCustomers={this.reloadCustomers}
        />
      ),
    });
  };
  private generateCustomer = (node: INode): JSX.Element => {
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Customer.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={2} className="centerText" key={1} value={node.Customer.CustomerNumber} />);
    dataItems.push(
      <DataItem
        flexVal={2}
        className="rightBorder leftBorder centerText"
        key={2}
        value={Convert.isEmptyOrSpaces(node.Customer.Name as string) ? "No name set" : node.Customer.Name}
      />
    );
    dataItems.push(
      <DataItem
        flexVal={3}
        className="rightBorder"
        key={3}
        value={Convert.isEmptyOrSpaces(node.Customer.Other as string) ? "No notes set" : node.Customer.Other}
      />
    );
    dataItems.push(<DataItem flexVal={1} className="centerText" key={4} value={node.SubscriptionCount.toString()} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.customerEditRequested} />;
  };
  private customerQueryExecute = (query: string) => {
    this.customerTable.current!.reload(query);
  };
  private deleteAllEmptyCustomers = async () => {
    let dResp = await Messages.Dialog.confirm(
      <div>
        <span>Are you absolutely sure you wish to delete all customers with no subscriptions for this publisher?</span>
      </div>,
      "Delete customers?",
      Messages.Dialog.Buttons.DeleteCancel
    );
    if (dResp === "true") {
      let response = await this.context.deleteEmptyCustomers({ PublisherId: this.state.currentPub!.PublisherId });
      if (response.valid()) {
        Messages.Notify.success("Customers deleted successfully!");
        this.customerTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  render() {
    if (!this.props.IsLoggedIn || this.state.allowedPubs.length <= 0 || !this.context.viewedViews.get(DashboardView.Customers)!.isLoaded()) {
      return "";
    }
    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          publisherList={this.state.allowedPubs}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.currentPub!}
          disabled={this.state.panelDisabled}
        />
        <div className="customerView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="customerViewInner">
              <div className="section">
                <h1>Welcome to the Customer View</h1>
                <p>
                  Welcome to the Customer View. This view is used to manage the various customers in the system. Customers are entities that group subscriptions
                  together. A customer generally represents an entity such as a publisher, a university, a law firm or any other entity that would own
                  subscriptions. All subscriptions are associated to a customer. In this view you can:
                </p>
                <ul>
                  <li>Create new customers</li>
                  <li>Modify Existing customers</li>
                  <li>View the subscriptions of a customer.</li>
                  {this.context.canManageSystem() && (
                    <li>
                      <b>Admin: </b>Delete customers. Removing the subscriptions and Licences associated to them.
                    </li>
                  )}
                </ul>
                <p>
                  If you are seeing this page, it&apos;s because you are listed as an administrator of the Dashboard system for proLibro Connect or you have
                  ManageSubscription/ManagePublisher rights to one or more publishers.
                </p>
                {this.context.canManageSystem() && (
                  <Button style={{ width: "fit-content", marginBottom: "5px" }} outline color="danger" onClick={this.deleteAllEmptyCustomers}>
                    Delete all Customers with no Subscriptions
                  </Button>
                )}
                <h2>Customers</h2>
                <DataTable
                  headers={["Customer Number", "Name", "Other information", "Subscription Count"]}
                  headerFlexes={[2, 2, 3, 1]}
                  flowProvider={this.customerFlowProvider}
                  initializeFlowProvider={this.initializeCustomer}
                  objectBuilder={this.generateCustomer}
                  ref={this.customerTable}
                  settingsOverride={Models.genericDataSettings}
                  searchQueryComitted={this.customerQueryExecute}
                  rowAddRequested={this.addCustomerRequested}
                />
              </div>
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
