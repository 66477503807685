import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { AppSession, LoginType } from 'src/models/AppSession';
import * as Models from 'src/models/dto/DashboardModels';
import { DashboardView } from 'src/ui/foundation/Layout';
import { Convert } from 'src/utilities/Helpers';

import { Drawer, DrawerContainer, Loading } from '../../foundation/Controls';
import { DataItem, DataRow, DataTable } from '../../foundation/DataTable';
import * as Messages from '../../foundation/Messages';
import { Action, INode, IRequest, IResponse } from '../../foundation/StandaloneCogniflow';
import { AppContext } from '../../state/Contextes';
import { PanelView } from '../PanelView';
import { LibraryConfigForm } from './LibraryConfigForm';
import { LibraryInternalizationForm } from './LibraryInternalizationForm';
import { LibraryInternationalizationView } from './LibraryInternationalizationView';
import { PublisherForm } from './PublisherForm';
import { PublisherLibraryConfigCreationForm } from './PublisherLibraryConfigCreationForm';

interface IPublisherViewProps {}

interface IPublisherViewState {
  isInitialized: boolean;
  name: string;
  selectedPublisher: number;
  logo: any;
  panelList: Models.ILoginLibrary[];
  loading: boolean;
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
}

export class PublisherView extends React.Component<IPublisherViewProps, IPublisherViewState> {
  context: AppSession;
  static contextType = AppContext;
  publisherForm = React.createRef<PublisherForm>();
  libraryConfigForm = React.createRef<LibraryConfigForm>();
  internalizationsTable = React.createRef<DataTable>();
  panelRef = React.createRef<PanelView>();
  publisherObject: Models.IPublisher;
  libConfigObject: Models.ILibraryConfig;

  constructor(props: IPublisherViewProps | Readonly<IPublisherViewProps>) {
    super(props);
    this.state = {
      isInitialized: false,
      name: "",
      selectedPublisher: 0,
      panelList: [],
      logo: "",
      loading: false,
      drawerShow: false,
      currentDrawerContent: null,
    };
  }

  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Publishers)!.loading.on(this.loginChanged);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Publishers)!.loading.off(this.loginChanged);
  }
  // On User Login
  loginChanged = () => {
    let Pubs = this.context.getManageablePublishers();

    let publisherList = Pubs;
    this.context.viewedViews.get(DashboardView.Publishers)!.progressLoading();
    this.setState(
      {
        panelList: publisherList,
        selectedPublisher: publisherList[0].PublisherId,
      },
      () => {
        this.publisherChanged(publisherList[0]);
      }
    );
  };

  publisherChangedId = (newPub: number) => {
    this.setState({ loading: true }, async () => {
      let publisher = await this.context.getPublisher({ PublisherId: newPub });
      this.setState(
        {
          selectedPublisher: newPub,
          name: publisher.data.Publisher.Name,
          logo: publisher.data.LibraryConfig.LibraryIcon,
          loading: false,
        },
        () => {
          this.libraryConfigForm.current!.initializeLibraryConfig(publisher.data.LibraryConfig);
          this.publisherForm.current!.initializePublisher(publisher.data.Publisher);
          this.reloadInternationalizationAndDismiss();
          this.libConfigObject = publisher.data.LibraryConfig;
        }
      );
    });
  };
  publisherChanged = (newPub: Models.ILoginLibrary) => {
    this.publisherChangedId(newPub.PublisherId);
  };

  publisherInsertRequest = () => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <div>
          <PublisherLibraryConfigCreationForm selectedPublisher={0} />
        </div>
      ),
    });
  };
  Logo = () => {
    const logo = this.state.logo;
    if (logo !== "") {
      return <img title="logo" src={`data:image/jpeg;base64,${String(logo)}`} width={120} />;
    }
    return;
  };
  private reloadPanelAndDismiss = () => {
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };
  private reloadInternationalizationAndDismiss = () => {
    this.internalizationsTable.current!.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };
  private saveInternationalization = async (e: Models.ILibraryInternationalizationViewModel) => {
    // debugger;
    let response = await this.context.insertOrUpdateLibraryInternationalization({
      LibraryInternationalization: e.LibraryInternationalization,
      PublisherId: this.state.selectedPublisher,
    });
    if (response.valid()) {
      Messages.Notify.success(`The Library Internationalization was saved successfully!`);
      // let infoResponse = await this.context.insertOrUpdateAboutInfo({
      //   AboutInfo: f.AboutInfo
      // });
      // if (infoResponse.valid()) {
      //   Messages.Notify.success(`The Library Internationalization and AboutInfos were saved successfully!`);
      // } else {
      //   Messages.Notify.success(`The Library Internationalization was saved successfully!`);
      //   if (infoResponse.errors.length > 0){
      //     Messages.Notify.error("AboutInfo save failed. Server reported: " + response.errors[0].Message);
      //   } else {
      //     Messages.Notify.error("An error occurred while executing the AboutInfo communication");
      //   }
      // }
      this.reloadInternationalizationAndDismiss();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private deleteInternationalization = async (e: Models.ILibraryInternationalizationViewModel) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete this Library Internationalization?`);
    if (result === "true") {
      let response = await this.context.deleteLibraryInternationalization({ LibraryInternationalization: e.LibraryInternationalization });
      if (response.valid()) {
        Messages.Notify.success(`The Library Internationalization item was deleted successfully!`);
        this.reloadInternationalizationAndDismiss();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private generateInternationalization = (n: INode): JSX.Element => {
    let node = n as Models.ILibraryInternationalizationViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.LibraryInternationalization.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={2} key={1} value={node.LibraryInternationalization.Name} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="leftBorder" value={Models.LanguageCode[node.LibraryInternationalization.Language]} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="leftBorder" value={node.LibraryInternationalization.ResetPasswordUrl} />);
    dataItems.push(<DataItem flexVal={2} key={4} className="leftBorder" value={node.LibraryInternationalization.RegistrationUrl} />);

    return (
      <DataRow
        node={node}
        key={node.LibraryInternationalization.TableId}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.libraryInternalizationEditRequest}
      />
    );
  };

  private initializeInternationalization = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowInternationalizations({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.selectedPublisher,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private internalizationFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowInternationalizations({ FlowRequest: request.Batches[0], PublisherId: this.state.selectedPublisher });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private libraryInternalizationEditRequest = (eNode: INode) => {
    let e = eNode as Models.ILibraryInternationalizationViewModel;
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <LibraryInternationalizationView
          initialNode={e}
          saveRequested={this.saveInternationalization}
          deleteRequested={this.deleteInternationalization}
          reloadPermissions={this.reloadInternationalizationAndDismiss}
        />
      ),
    });
  };
  private libraryInternalizationInsertRequest = () => {
    let blankModel: Models.ILibraryInternationalizationViewModel = {
      LibraryInternationalization: {
        Name: "",
        AccountNotActiveMessage: "",
        Language: Models.LanguageCode.English,
        LibraryConfigId: this.libConfigObject.TableId,
        LibraryNoTitlesMessage: "",
        LicenceAgreementLink: "",
        LoginCustomPlaceholder: "",
        LoginFlavourText: "",
        PrivacyPolicyLink: "",
        RegistrationUrl: "",
        ResetPasswordUrl: "",
        TableId: 0,
        AboutInfos: [],
      },
      Index: -1,
      IsFirst: false,
      IsLast: false,
    };
    // let blankInfo: Models.IAboutInfoViewModel = {
    //   AboutInfo: {
    //     TableId: -1,
    //     LibraryInternationalizationId: -1,
    //     Html: '',
    //     Title: ''
    //   },
    //   Index: -1,
    //   IsFirst: false,
    //   IsLast: false
    // };

    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <LibraryInternalizationForm
          initialNode={blankModel}
          saveRequested={this.saveInternationalization}
          reloadPermissions={this.reloadInternationalizationAndDismiss}
        />
      ),
    });
  };
  private internalizationQueryExecute = (query: string) => {
    this.internalizationsTable.current!.reload(query);
  };

  render() {
    let settings = Models.genericDataSettings;
    settings.batchSize = 25;
    if (
      this.context.login === LoginType.None ||
      this.context.getManageablePublishers().length === 0 ||
      !this.context.viewedViews.get(DashboardView.Publishers)!.isLoaded()
    ) {
      return (
        <div className="section">
          <h1>You cannot view this page</h1>
        </div>
      );
    }

    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          ref={this.panelRef}
          disabled={this.state.loading}
          publisherList={this.state.panelList}
          publisherChanged={this.publisherChanged}
          addPublisher={this.publisherInsertRequest}
          showAdd={this.context.canManageSystem()}
          selectedPublisher={this.state.panelList.find((x) => x.PublisherId === this.state.selectedPublisher)!}
        />

        <div className="publisherView">
          <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Publisher...">
            <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
              <Drawer
                onBackdropClicked={() => {
                  this.setState({ drawerShow: false, currentDrawerContent: null });
                }}
                isOpen={this.state.drawerShow}
                backdrop={true}
                className="details-view internationalizationsDrawer"
              >
                {this.state.currentDrawerContent}
              </Drawer>
              <div className="section">
                <div className="header-box">
                  <div className="content-publisher-logo">{this.Logo()}</div>
                  <h3 className="content-publisher-name">{this.state.name}</h3>
                </div>
                <Tabs defaultActiveKey="pub-info" id="uncontrolled-tab-example">
                  <Tab eventKey="pub-info" title="Publisher Info">
                    <PublisherForm ref={this.publisherForm} selectedPublisher={this.state.selectedPublisher} />
                  </Tab>
                  <Tab eventKey="lib-config" title="Library Config">
                    <LibraryConfigForm
                      ref={this.libraryConfigForm}
                      selectedPublisher={this.state.selectedPublisher}
                      publisherChangedId={this.publisherChangedId}
                    />
                  </Tab>
                  <Tab eventKey="inter-config" title="Internationalizations">
                    <DataTable
                      headers={["Name", "Language", "ResetPasswordUrl", "RegistrationUrl"]}
                      headerFlexes={[2, 2, 2, 2]}
                      rowAddRequested={this.libraryInternalizationInsertRequest}
                      flowProvider={this.internalizationFlowProvider}
                      initializeFlowProvider={this.initializeInternationalization}
                      objectBuilder={this.generateInternationalization}
                      ref={this.internalizationsTable}
                      settingsOverride={settings}
                      searchQueryComitted={this.internalizationQueryExecute}
                    />
                  </Tab>
                </Tabs>
              </div>
            </DrawerContainer>
          </Loading>
        </div>
      </div>
    );
  }
}
