import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { AppSession } from 'src/models/AppSession';
import { Loading } from 'src/ui/foundation/Controls';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { Action, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';

export interface IEditPublicationPackDefinitionFormProps {
  initialNode: Models.IPublicationPackDefinitionViewModel;
  createMode: boolean;
  reloadPublicationPackDefinitions: () => void;
  dismissDrawer: () => void;
}
export interface IEditPublicationPackDefinitionFormState {
  activeTab: PublicationPackDefinitionTab;
  isCreateMode: boolean;
  loading: boolean;
  editingNode: Models.IPublicationPackDefinitionViewModel;
  editingUserRange: Models.IUserLicenseRange | null;
}
enum PublicationPackDefinitionTab {
  Properties,
  UserLicenseRanges,
}
export class EditPublicationPackDefinitionForm extends React.Component<IEditPublicationPackDefinitionFormProps, IEditPublicationPackDefinitionFormState> {
  context: AppSession;
  static contextType = AppContext;
  userLicenseRangeTable = React.createRef<DataTable>();

  constructor(props: IEditPublicationPackDefinitionFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      activeTab: PublicationPackDefinitionTab.Properties,
      loading: false,
      isCreateMode: props.createMode,
      editingUserRange: null,
    };
  }
  packDefNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPackDefinition: { ...prevState.editingNode.PublicationPackDefinition, PackDefName: e.target.value } },
    }));
  };
  packDefDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        PublicationPackDefinition: { ...prevState.editingNode.PublicationPackDefinition, PackDefDescription: e.target.value },
      },
    }));
  };
  handleUserLicenseInvoicingChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        PublicationPackDefinition: { ...prevState.editingNode.PublicationPackDefinition, UserLicenseInvoicing: +arg.target.value },
      },
    }));
  };
  handlePublicationPackInvoicingChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        PublicationPackDefinition: { ...prevState.editingNode.PublicationPackDefinition, PublicationPackInvoicing: +arg.target.value },
      },
    }));
  };
  handleUserLicenseStartRangeChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => {
      prevState.editingUserRange!.StartRange = +arg.target.value;
      return prevState;
    });
  };
  handleUserLicenseFrontlistChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => {
      prevState.editingUserRange!.FrontListPricePerUser = +arg.target.value;
      return prevState;
    });
  };
  handleUserLicenseBacklistChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => {
      prevState.editingUserRange!.BackListPricePerUser = +arg.target.value;
      return prevState;
    });
  };
  handleUserLicenseMaintenanceChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => {
      prevState.editingUserRange!.MaintenancePricePerUser = +arg.target.value;
      return prevState;
    });
  };
  handleBillingCurrencyChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        PublicationPackDefinition: { ...prevState.editingNode.PublicationPackDefinition, BillingCurrency: +arg.target.value },
      },
    }));
  };

  private initializeUserLicenseRanges = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowUserLicenseRanges({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublicationPackDefId: this.state.editingNode.PublicationPackDefinition.TableId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private userLicenseRangeFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowUserLicenseRanges({
        FlowRequest: request.Batches[0],
        PublicationPackDefId: this.state.editingNode.PublicationPackDefinition.TableId,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  generateUserLicenseRange = (n: INode) => {
    let node = n as Models.IUserLicenseRangeViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={1} key={1} className="centerText" value={node.StartRange.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="centerText" value={node.EndRange === -1 ? "Unlimited" : node.EndRange.toString()} />);
    dataItems.push(
      <DataItem
        flexVal={5}
        key={3}
        className="centerText"
        value={Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, node.FrontListPricePerUser)}
      />
    );

    return (
      <DataRow
        className={this.state.editingUserRange !== null && node.TableId === this.state.editingUserRange.TableId ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.editUserLicenseRange}
      />
    );
  };
  editUserLicenseRange = (item: INode) => {
    this.setState({ editingUserRange: item as Models.IUserLicenseRange }, () => this.userLicenseRangeTable.current!.reRender());
  };
  addUserLicenseRange = () => {
    let newRange: Models.IUserLicenseRange | null = null;
    if (this.state.editingNode.UserLicenseRanges.length === 0) {
      newRange = {
        BackListPricePerUser: 0,
        FrontListPricePerUser: 0,
        MaintenancePricePerUser: 0,
        PublicationPackDefinitionId: this.state.editingNode.PublicationPackDefinition.TableId,
        StartRange: 0,
        TableId: 0,
      };
    } else {
      let lastone = this.state.editingNode.UserLicenseRanges[this.state.editingNode.UserLicenseRanges.length - 1];
      newRange = {
        BackListPricePerUser: lastone.BackListPricePerUser,
        FrontListPricePerUser: lastone.FrontListPricePerUser,
        MaintenancePricePerUser: lastone.MaintenancePricePerUser,
        PublicationPackDefinitionId: lastone.PublicationPackDefinitionId,
        StartRange: lastone.StartRange,
        TableId: 0,
      };
    }
    this.setState({ editingUserRange: newRange }, () => this.userLicenseRangeTable.current!.reRender());
  };
  isUserLicenseFormValid = () => {
    if (
      this.state.editingNode.UserLicenseRanges.some(
        (x) => x.StartRange === this.state.editingUserRange!.StartRange && this.state.editingUserRange!.TableId <= 0
      )
    ) {
      Messages.Notify.error("There's already a range at this tier.");
      return false;
    } else if (
      this.state.editingUserRange!.FrontListPricePerUser < 0 ||
      this.state.editingUserRange!.BackListPricePerUser < 0 ||
      this.state.editingUserRange!.MaintenancePricePerUser < 0
    ) {
      Messages.Notify.error("Billing price cannot be negative.");
      return false;
    } else if (this.state.editingUserRange!.StartRange < 0) {
      Messages.Notify.error("Range cannot be negative");
      return false;
    }

    return true;
  };
  saveUserLicenseRange = async () => {
    if (this.isUserLicenseFormValid()) {
      let response = await this.context.insertOrUpdateUserLicenseRange({ UserLicenseRange: this.state.editingUserRange! });
      if (response.valid()) {
        Messages.Notify.success("User license range saved successfully!");
        this.setState({ editingUserRange: null }, () => {
          if (this.userLicenseRangeTable.current !== null) {
            this.userLicenseRangeTable.current.reload();
          }
        });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };

  deleteUserLicenseRange = async () => {
    let result = await Messages.Dialog.confirm(
      `Are you sure you wish to delete this user license range? This change will affect the current billing cycle for all packs using this definition.`,
      `Delete User License Range`
    );
    if (result === "true") {
      this.setState({ loading: true }, async () => {
        let response = await this.context.deleteUserLicenseRange({ UserLicenseRange: this.state.editingUserRange! });
        if (response.valid()) {
          Messages.Notify.success("User license range deleted successfully!");
          this.setState(
            (prevState) => {
              let index = prevState.editingNode.UserLicenseRanges.findIndex((x) => x.TableId === prevState.editingUserRange!.TableId);
              if (index > -1) {
                prevState.editingNode.UserLicenseRanges.splice(index, 1);
              }
              return prevState;
            },
            () => {
              this.setState({ editingUserRange: null }, () => {
                if (this.userLicenseRangeTable.current !== null) {
                  this.userLicenseRangeTable.current.reload();
                }
              });
            }
          );
        } else {
          if (response.errors.length > 0) {
            Messages.Notify.error(response.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  };

  savePublicationPackDefinition = () => {
    if (this.isPublicationDefinitionValid()) {
      this.setState({ loading: true }, async () => {
        let response = await this.context.insertOrUpdatePublicationPackDefinition({
          PublicationPackDefinition: this.state.editingNode.PublicationPackDefinition,
        });
        if (response.valid()) {
          Messages.Notify.success("Publication pack definition saved successfully!");
          this.props.reloadPublicationPackDefinitions();
          this.setState({ editingNode: response.data.PublicationPackDefinition, isCreateMode: false });
        } else {
          if (response.errors.length > 0) {
            Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  };

  deletePublicationPackDefinition = async () => {
    let result = await Messages.Dialog.confirm(
      `Are you sure you wish to delete this publication pack definition? New publication packs will not be able to use this pack.`,
      `Delete Publication Pack Definition`
    );
    if (result === "true") {
      this.setState({ loading: true }, async () => {
        let response = await this.context.deletePublicationPackDefinition({ PublicationPackDefinition: this.state.editingNode.PublicationPackDefinition });
        if (response.valid()) {
          Messages.Notify.success("Publication pack definition deleted successfully!");
          this.props.reloadPublicationPackDefinitions();
          this.props.dismissDrawer();
        } else {
          if (response.errors.length > 0) {
            Messages.Notify.error(response.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  };

  isPublicationDefinitionValid = () => {
    if (Convert.isEmptyOrSpaces(this.state.editingNode.PublicationPackDefinition.PackDefName)) {
      Messages.Notify.error("Pack definition name cannot be empty");
      return false;
    } else if (this.state.editingNode.PublicationPackDefinition.BillingCurrency <= 0) {
      Messages.Notify.error("Billing currency is invalid");
      return false;
    } else if (this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing < 0) {
      Messages.Notify.error("Publication Pack Invoicing is invalid");
      return false;
    } else if (this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing < 0) {
      Messages.Notify.error("User License Invoicing is invalid");
      return false;
    }

    return true;
  };

  render() {
    let allRanges = JSON.parse(JSON.stringify(Models.genericDataSettings));
    allRanges.batchSize = 10000;
    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Publication Pack Definition...">
        <div className="form-container full-width full-height">
          <h3>Publication pack definition management: {this.state.editingNode.PublicationPackDefinition.PackDefName}</h3>
          <div className="billing-tabs">
            <Tabs defaultActiveKey={this.state.activeTab} id="billingTabs">
              <Tab eventKey={PublicationPackDefinitionTab.Properties} title={"Pack definition"}>
                <div className="full-width full-height publicationPackRights">
                  <p>
                    Publication pack definitions are sets of rules that a publication pack needs to follow. They outline billing currency, how the publication
                    pack calculates billing and how user license billing is calculated and tiered. You can set the user license ranges and other rules by
                    clicking on one of the definitions below. Many publication packs can be associated to a definition so keep this in mind when editing
                    properties here.
                  </p>
                  <Col>
                    <Row>
                      <FormGroup>
                        <Label for="packDefName">Publication pack definition name</Label>
                        <Input
                          value={this.state.editingNode.PublicationPackDefinition.PackDefName}
                          onChange={this.packDefNameChanged}
                          type="text"
                          name="packDefName"
                          id="packDefName"
                          placeholder="Publication pack definition name"
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="packDefDescription">Publication pack definition description</Label>
                        <Input
                          value={this.state.editingNode.PublicationPackDefinition.PackDefDescription}
                          onChange={this.packDefDescriptionChanged}
                          type="text"
                          name="packDefDescription"
                          id="packDefDescription"
                          placeholder="Publication pack definition description"
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="userLicenseInvoicing">User license invoicing</Label>
                        <Input
                          value={this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing}
                          onChange={this.handleUserLicenseInvoicingChanged}
                          type="select"
                          name="userLicenseInvoicing"
                          id="userLicenseInvoicing"
                        >
                          {Object.keys(Models.UserLicenseInvoicing)
                            .filter((key) => isNaN(Number(Models.UserLicenseInvoicing[key as keyof typeof Models.UserLicenseInvoicing])))
                            .map((it) => (
                              <option value={it} key={it} data-providerval={it}>
                                {Models.UserLicenseInvoicing[it as keyof typeof Models.UserLicenseInvoicing]}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="publicationPackInvoicing">Publication pack invoicing</Label>
                        <Input
                          value={this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing}
                          onChange={this.handlePublicationPackInvoicingChanged}
                          type="select"
                          name="publicationPackInvoicing"
                          id="publicationPackInvoicing"
                        >
                          {Object.keys(Models.PublicationPackInvoicing)
                            .filter((key) => isNaN(Number(Models.PublicationPackInvoicing[key as keyof typeof Models.PublicationPackInvoicing])))
                            .map((it) => (
                              <option value={it} key={it} data-providerval={it}>
                                {Models.PublicationPackInvoicing[it as keyof typeof Models.PublicationPackInvoicing]}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="billingCurrency">Billing currency</Label>
                        <Input
                          value={this.state.editingNode.PublicationPackDefinition.BillingCurrency}
                          onChange={this.handleBillingCurrencyChanged}
                          type="select"
                          name="billingCurrency"
                          id="billingCurrency"
                        >
                          {Object.keys(Models.CurrencyCodes)
                            .sort()
                            .filter((key) => isNaN(Number(Models.CurrencyCodes[key as keyof typeof Models.CurrencyCodes])))
                            .map((it) => (
                              <option value={it} key={it} data-providerval={it}>
                                {Models.CurrencyCodes[it as keyof typeof Models.CurrencyCodes]}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                    </Row>
                  </Col>
                </div>
              </Tab>
              {!this.state.isCreateMode && (
                <Tab eventKey={PublicationPackDefinitionTab.UserLicenseRanges} title={"User License Ranges"}>
                  <div>
                    <p>
                      Here are the user licensing ranges for the current publication pack. Licenses created or associated to titles in this pack are charged
                      along the tiers outlined below.
                    </p>
                    {this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.OneTimeInvoice && (
                      <p>
                        This selected pack is billed once per license creation and does not recurr during subsequent billing cycles. Billing is calculated based
                        on the number of license associations made during the active cycle and discounts may apply. This count is resent every cycle. See ranges
                        below.
                      </p>
                    )}
                    {this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.RecurringPerAnnum && (
                      <p>
                        This selected pack is billed based on the number of licenses existing for titles in this pack. Discounts may be applied based on the
                        number of active (non-expired) licenses for titles in this pack. See ranges below.
                      </p>
                    )}
                    <DataTable
                      headers={[
                        "License start range",
                        "License end range",
                        "Cost per license" +
                          (this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.RecurringPerAnnum
                            ? " (per year)"
                            : " created in a year"),
                      ]}
                      headerFlexes={[1, 1, 5]}
                      flowProvider={this.userLicenseRangeFlowProvider}
                      initializeFlowProvider={this.initializeUserLicenseRanges}
                      objectBuilder={this.generateUserLicenseRange}
                      ref={this.userLicenseRangeTable}
                      settingsOverride={allRanges}
                      rowAddRequested={this.addUserLicenseRange}
                      canDelete={this.state.editingUserRange !== null && this.state.editingUserRange.TableId > 0}
                      selectedRowDelete={this.deleteUserLicenseRange}
                    />
                    {this.state.editingUserRange !== null && (
                      <div style={{ padding: "15px" }}>
                        <h2>{this.state.editingUserRange.TableId <= 0 ? "New user license range" : "Editing user license range"}</h2>
                        <Col>
                          <Row>
                            <FormGroup>
                              <Label for="userLicenseStartRange">Starting range</Label>
                              <Input
                                value={this.state.editingUserRange.StartRange}
                                onChange={this.handleUserLicenseStartRangeChanged}
                                type="number"
                                name="userLicenseStartRange"
                                id="userLicenseStartRange"
                              />
                            </FormGroup>
                          </Row>
                          <Row>
                            <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                              <Label for="userLicenseFrontlist">Frontlist pricing per user license</Label>
                              <Input
                                value={this.state.editingUserRange.FrontListPricePerUser}
                                onChange={this.handleUserLicenseFrontlistChanged}
                                type="number"
                                name="userLicenseFrontlist"
                                id="userLicenseFrontlist"
                              />
                            </FormGroup>
                            <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                              <Label for="userLicenseBacklist">Backlist pricing per user license</Label>
                              <Input
                                value={this.state.editingUserRange.BackListPricePerUser}
                                onChange={this.handleUserLicenseBacklistChanged}
                                type="number"
                                name="userLicenseBacklist"
                                id="userLicenseBacklist"
                              />
                            </FormGroup>
                            <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                              <Label for="userLicenseMaintenancelist">Maintenance pricing per user license</Label>
                              <Input
                                value={this.state.editingUserRange.MaintenancePricePerUser}
                                onChange={this.handleUserLicenseMaintenanceChanged}
                                type="number"
                                name="userLicenseMaintenancelist"
                                id="userLicenseMaintenancelist"
                              />
                            </FormGroup>
                          </Row>
                          <Button style={{ marginRight: "15px" }} outline color="info" onClick={this.saveUserLicenseRange}>
                            Save User License Range
                          </Button>
                        </Col>
                      </div>
                    )}
                  </div>
                </Tab>
              )}
            </Tabs>
            {this.context.canManageSystem() && (
              <FormGroup>
                <Col>
                  <Row>
                    <Button style={{ flex: "1", marginRight: "10px" }} outline color="primary" onClick={this.savePublicationPackDefinition}>
                      Save Publication Pack Definition
                    </Button>
                    {!this.state.isCreateMode && (
                      <Button style={{ flex: "1", marginLeft: "10px" }} outline color="danger" onClick={this.deletePublicationPackDefinition}>
                        Delete Publication Pack Definition
                      </Button>
                    )}
                  </Row>
                </Col>
              </FormGroup>
            )}
          </div>
        </div>
      </Loading>
    );
  }
}
