import { IAdminStatistics, IPublisherStatistics } from './DashboardModels';
import { IChartDataPoint, IGraphingDateInterval } from './ReportingModels';

export interface IGetAdminStatistics {}
export interface IGetAdminStatisticsR {
  AdminStatistics: IAdminStatistics;
}
export interface IGetPromoItems {}
export interface IGetPromoItemsR {
  PromoCsv: string;
}
export interface IGetPublisherStatistics {
  PublisherId: number;
}
export interface IGetPublisherStatisticsR {
  PublisherStatistics: IPublisherStatistics;
}

export interface IGetTopLicensesByPublisher {
  Interval: IGraphingDateInterval;
}
export interface IGetTopProductsForPublisher {
  Interval: IGraphingDateInterval;
  PublisherId: number;
}
export interface IGraphingR {
  DataPoints: IChartDataPoint[];
}

export interface IGetTitleActivityCsv {
  StartDate: Date;
  EndDate: Date;
  PublisherId: number;
}
export interface IGetNewLicensesCsv {
  StartDate: Date;
  EndDate: Date;
  PublisherId: number;
}

export interface ICsvR {
  CsvData: string;
}

export interface IGetNewProviderAccountsCsv {
  StartDate: Date;
  EndDate: Date;
  PublisherId: number;
}

export interface IReportingRequest {
  ReportType: ReportType;
  ReportSubType: ReportSubType;
  GenerateCsv: boolean;
  Interval: IGraphingDateInterval;
  PublisherId: number;
  ExtraArgs: any;
}
export interface IReportingResponse {
  Points: IChartDataPoint[];
  CsvData: string;
  ExtraArgs: any;
}

export enum ReportType {
  TitleActivityPublisher,
  NewLicensesPublisher,
  NewProviderAccountsPublisher,
  Product,
  Version,
  GlobalNote,
  Tip,
  Feedback,
  Bulletin,
  UserCount,
  Adoption,
  TopTitles,
  BookDownloads,
  OperatingSystem,
  User,
  Permissions,
  ActiveLicenses,
  Announcements,
  StoreFront
}
export enum ReportSubType {
  None,
  EmployeeType,
  Geography,
  EmployeeTypeAndGeography,
}
