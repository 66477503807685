import * as React from 'react';
import {
    Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row
} from 'reactstrap';
import { AppSession, LoginType } from 'src/models/AppSession';
import { IUserAccount } from 'src/models/dto/DashboardModels';
import { FormState } from 'src/utilities/FormState';
import { Convert } from 'src/utilities/Helpers';
import { Validator } from 'src/utilities/Validator';

import * as Models from '../../../models/dto/DashboardModels';
import { Image } from '../../foundation/Assets';
import { Icon } from '../../foundation/Controls';
import * as Messages from '../../foundation/Messages';
import { AppContext } from '../../state/Contextes';

export interface IProfileFormProps {}
export interface IProfileFormState {
  isInitialized: boolean;
  name: string;
  form: FormState;
}
export class ProfileForm extends React.Component<IProfileFormProps, IProfileFormState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IProfileFormProps) {
    super(props);
    let form = new FormState({
      DisplayName: "",
      Email: "",
      PhoneNumber: "",
      FirstName: "",
      LastName: "",
      SocialAddr: "",
      SocialAddr2: "",
      Occupation: "",
      TableGuid: "",
      TableId: "",
    });
    this.state = {
      isInitialized: false,
      name: "",
      form,
    };
  }

  componentDidMount = () => {
    let user = this.context.user!;
    let form = new FormState({
      DisplayName: user?.DisplayName,
      Email: user?.Email,
      PhoneNumber: user?.PhoneNumber,
      FirstName: user?.FirstName,
      LastName: user?.LastName,
      SocialAddr: user?.SocialAddr,
      SocialAddr2: user?.SocialAddr2,
      Occupation: user?.Occupation,
      TableGuid: user?.TableGuid,
      TableId: user?.TableId,
      DateAdded: user?.DateAdded,
      DateLastUsed: user?.DateLastUsed,
      LockUntilDate: user?.LockUntilDate,
      LanguageType: user?.LanguageType,
      UserStatus: user?.UserStatus,
      Industry: user?.Industry,
      Locked: user?.Locked,
    });
    let name = user?.DisplayName ?? "";

    form.addValidator("email", Validator.isEmail, "Invalid Email");
    this.setState({
      name: name,
      form: form,
      isInitialized: true,
    });
  };
  firstNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState({
      form: this.state.form.change(e.target),
    });
  };
  handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    this.setState({
      form: this.state.form.change(event.target),
    });
  };

  submit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (this.state.form.values === undefined) {
      return;
    }
    let user: IUserAccount = {
      DateAdded: this.state.form.values.DateAdded,
      DateLastUsed: this.state.form.values.DateLastUsed,
      LockUntilDate: this.state.form.values.LockUntilDate,
      TableId: this.state.form.values.TableId,
      TableGuid: this.state.form.values.TableGuid,
      DisplayName: this.state.form.values.DisplayName,
      Email: this.state.form.values.Email,
      PhoneNumber: this.state.form.values.PhoneNumber,
      SocialAddr: this.state.form.values.SocialAddr,
      SocialAddr2: this.state.form.values.SocialAddr2,
      LoginName: this.state.form.values.LoginName,
      Occupation: this.state.form.values.Occupation,
      LanguageType: this.state.form.values.LanguageType,
      UserStatus: this.state.form.values.UserStatus,
      Locked: this.state.form.values.Locked,
      Industry: this.state.form.values.Industry,
      FirstName: this.state.form.values.FirstName,
      LastName: this.state.form.values.LastName,
      IsSso: this.state.form.values.IsSso,
      PrivacyConsent: this.state.form.values.PrivacyConsent,
      PromoConsent: this.state.form.values.PromoConsent,
    };
    let response = await this.context.updateUserProfile({ User: user });

    if (response.valid()) {
      Messages.Notify.success(`Your Profile  ${user.DisplayName} was updated successfully!`);
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Update failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  render() {
    if (this.context.login === LoginType.None) {
      return (
        <div className="section">
          <h1>You cannot view this page</h1>
        </div>
      );
    }
    let form = this.state.form;
    let user = this.context.user!;
    if (user === null || user === undefined) {
      return;
    }
    let dateAddedControl: JSX.Element;
    if (this.state.form.values.DateAdded === null) {
      dateAddedControl = <Input disabled value={"Never"} type="text" name="DateAdded" id="DateAdded" />;
    } else {
      dateAddedControl = (
        <Input
          disabled={true}
          type="date"
          name="DateAdded"
          id="DateAdded"
          value={Convert.formatDateForForm(new Date(this.state.form.values.DateAdded as Date))}
        />
      );
    }
    let dateLastUsedControl: JSX.Element;
    if (this.state.form.values.DateLastUsed === null) {
      dateLastUsedControl = <Input disabled value={"Never"} type="text" name="DateLastUsed" id="DateLastUsed" />;
    } else {
      dateLastUsedControl = (
        <Input
          disabled={true}
          type="date"
          name="DateLastUsed"
          id="DateLastUsed"
          value={Convert.formatDateForForm(new Date(this.state.form.values.DateLastUsed as Date))}
        />
      );
    }

    return (
      <div className="formContainer">
        <Form>
          <Row style={{ flex: "1", display: "flex", paddingTop: "8px" }}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.address_book />} /> &nbsp; First Name
                  </InputGroupText>
                  <Input
                    type="text"
                    name="FirstName"
                    defaultValue={user.FirstName || ""}
                    invalid={form.invalid("FirstName")}
                    placeholder="First Name"
                    onChange={this.firstNameChanged}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.address_book />} /> &nbsp; Last Name
                  </InputGroupText>
                  <Input
                    type="text"
                    name="LastName"
                    defaultValue={user.LastName || ""}
                    invalid={form.invalid("LastName")}
                    placeholder="Last Name"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ flex: "1", display: "flex" }}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} /> &nbsp; Display Name
                  </InputGroupText>
                  <Input
                    type="text"
                    name="DisplayName"
                    defaultValue={user.DisplayName || ""}
                    invalid={form.invalid("DisplayName")}
                    placeholder="Display Name"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.envelop />} /> &nbsp; Email
                  </InputGroupText>
                  <Input
                    type="text"
                    name="Email"
                    defaultValue={user.Email || ""}
                    invalid={form.invalid("Email")}
                    placeholder="Email"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ flex: "1", display: "flex" }}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} /> &nbsp; Login Name
                  </InputGroupText>
                  <Input
                    type="text"
                    name="LoginName"
                    defaultValue={user.LoginName || ""}
                    invalid={form.invalid("LoginName")}
                    placeholder="Login Name"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; Phone Number
                  </InputGroupText>
                  <Input
                    type="text"
                    name="PhoneNumber"
                    defaultValue={user.PhoneNumber || ""}
                    invalid={form.invalid("PhoneNumber")}
                    placeholder="Phone Number"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ flex: "1", display: "flex" }}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.address_book />} /> &nbsp; Social Address
                  </InputGroupText>
                  <Input
                    type="text"
                    name="SocialAddr"
                    defaultValue={user.SocialAddr || ""}
                    invalid={form.invalid("SocialAddr")}
                    placeholder="Social Address"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.address_book />} /> &nbsp; Social Address 2
                  </InputGroupText>
                  <Input
                    type="text"
                    name="SocialAddr2"
                    defaultValue={user.SocialAddr2 || ""}
                    invalid={form.invalid("SocialAddr2")}
                    placeholder="Social Address 2"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ flex: "1", display: "flex" }}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} /> &nbsp; Language Type
                  </InputGroupText>
                  <Input
                    type="select"
                    name="LanguageType"
                    defaultValue={user.LanguageType || ""}
                    invalid={form.invalid("LanguageType")}
                    placeholder="LanguageType"
                    onChange={this.handleInput}
                  >
                    {Object.keys(Models.LanguageCode)
                      .filter((key) => isNaN(Number(Models.LanguageCode[key as keyof typeof Models.LanguageCode])))
                      .map((it) => (
                        <option value={it} key={it} data-providerval={it}>
                          {+it === Models.LanguageCode.Undefined ? "Language*" : Models.LanguageCode[it as keyof typeof Models.LanguageCode]}
                        </option>
                      ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} /> &nbsp; Industry
                  </InputGroupText>
                  <Input
                    type="select"
                    name="Industry"
                    defaultValue={user.Industry || ""}
                    invalid={form.invalid("Industry")}
                    placeholder="Industry"
                    onChange={this.handleInput}
                  >
                    {Object.keys(Models.IndustryType)
                      .filter((key) => isNaN(Number(Models.IndustryType[key as keyof typeof Models.IndustryType])))
                      .map((it) => (
                        <option value={it} key={it} data-providerval={it}>
                          {+it === Models.IndustryType.Undefined ? "Industry*" : Models.IndustryType[it as keyof typeof Models.IndustryType]}
                        </option>
                      ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.address_book />} /> &nbsp; Occupation
                  </InputGroupText>
                  <Input
                    type="text"
                    name="Occupation"
                    defaultValue={user.Occupation}
                    invalid={form.invalid("Occupation")}
                    placeholder="Occupation"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ flex: "1", display: "flex" }}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.user />} /> &nbsp; User Status
                  </InputGroupText>
                  <Input type="select" name="UserStatus" defaultValue={user.UserStatus || ""} invalid={form.invalid("userStatus")} onChange={this.handleInput}>
                    {Object.keys(Models.UserStatus)
                      .filter((key) => isNaN(Number(Models.UserStatus[key as keyof typeof Models.UserStatus])))
                      .map((it) => (
                        <option value={it} key={it} data-providerval={it}>
                          {Models.UserStatus[it as keyof typeof Models.UserStatus]}
                        </option>
                      ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.lock />} /> &nbsp; Locked
                  </InputGroupText>
                  <Label check for="Locked">
                    <Input
                      disabled
                      style={{ flex: "1", margin: "13px" }}
                      onChange={this.handleInput}
                      defaultChecked={user.Locked || false}
                      type="checkbox"
                      name="Locked"
                      id="Locked"
                    />
                  </Label>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.history />} /> &nbsp; Date Added
                  </InputGroupText>
                  {dateAddedControl}
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row style={{ flex: "1", display: "flex" }}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.history />} /> &nbsp; Last Login
                  </InputGroupText>
                  {dateLastUsedControl}
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          {
            <FormGroup style={{ flex: "1", display: "flex", justifyContent: "center" }}>
              <Col xs={8}>
                <Button color="primary" outline block disabled={!form.formValid()} onClick={this.submit}>
                  Update Profile
                </Button>
              </Col>
            </FormGroup>
          }
        </Form>
      </div>
    );
  }
}
