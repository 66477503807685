import React from "react";
import ReactDOMClient from "react-dom/client";

import App from "./App";

let container: HTMLElement | null = null;
document.addEventListener("DOMContentLoaded", () => {
  if (container === null) {
    container = document.getElementById("root");
    if (container != null) {
      document.documentElement.setAttribute("data-color-mode", "light");
      // Create a root.
      const root = ReactDOMClient.createRoot(container);

      // Initial render: Render an element to the root.
      root.render(<App />);

      if (module.hot) {
        module.hot.accept();
      }
    }
  }
});
