import "react-datepicker/dist/react-datepicker.css";

import * as React from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { ProLibroMarkdown } from "src/ui/foundation/Controls/proLibroMarkdown";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";
import * as Messages from "../../foundation/Messages";

import * as Models from "../../../models/dto/DashboardModels";
import StoreTerm, { StoreListingTermRange } from "./StoreTerm";
import { Tab, Tabs } from "react-bootstrap";
import { StoreListingSaleForm } from "./StoreListingSaleForm";

enum StorefrontTab {
  Info,
  Sales,
  Reviews,
}

export interface IStoreFrontFormProps {
  initialNode: Models.IStoreListingViewModel;
  publisherId: number;
  saveRequested?: (node: Models.IStoreListingViewModel) => void;
  creatingMode: boolean;
}
export interface IStoreFrontFormState {
  editingNode: Models.IStoreListingViewModel;
  loading: boolean;
  activeTab: StorefrontTab;
  salesMode: boolean;
  editingSale: Models.IStoreListingTermSaleViewModel | null;
  createMode: boolean;
}
export class StoreFrontForm extends React.Component<IStoreFrontFormProps, IStoreFrontFormState> {
  context: AppSession;
  static contextType = AppContext;
  productTable = React.createRef<DataTable>();
  reviewTable = React.createRef<DataTable>();
  salesTable = React.createRef<DataTable>();

  constructor(props: IStoreFrontFormProps) {
    super(props);
    this.state = {
      editingNode: {
        StoreListing: this.props.initialNode.StoreListing,
        TermLengths: this.props.initialNode.TermLengths,
        Index: this.props.initialNode.Index,
        IsFirst: this.props.initialNode.IsFirst,
        IsLast: this.props.initialNode.IsLast,
      },
      loading: false,
      activeTab: StorefrontTab.Info,
      salesMode: false,
      createMode: false,
      editingSale: null,
    };
    this.saveTermSale = this.saveTermSale.bind(this);
  }
  componentDidMount() {}

  handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, StoreListing: { ...prevState.editingNode.StoreListing, Name: e.target.value } },
    }));
  };
  startDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, StoreListing: { ...prevState.editingNode.StoreListing, StartDate: dato } },
    }));
  };
  endDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, StoreListing: { ...prevState.editingNode.StoreListing, EndDate: dato } },
    }));
  };
  statusChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, StoreListing: { ...prevState.editingNode.StoreListing, IsActive: e.target.checked } },
    }));
  };

  isSpotlightChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, StoreListing: { ...prevState.editingNode.StoreListing, IsSpotlight: e.target.checked } },
    }));
  };

  updatePriceForTermLength = (termLengthToUpdate: number, newPrice: number) => {
    const existing = this.state.editingNode.TermLengths.find((item) => item.TermLength === termLengthToUpdate);
    if (existing) {
      const now = new Date();
      const lastModified = new Date(existing.LastModificationDate);
      const diffDays = Math.floor((now.getTime() - lastModified.getTime()) / (1000 * 60 * 60 * 24));

      if (diffDays < 30) {
        Messages.Notify.error("Price can only be changed once every 30 days.");
        return;
      }
      this.setState((prevState) => ({
        editingNode: {
          ...prevState.editingNode,
          TermLengths: prevState.editingNode.TermLengths.map((item) => {
            if (item.TermLength === termLengthToUpdate) {
              if (isNaN(newPrice) || newPrice < 0) {
                return { ...item, Price: 0 };
              }
              return { ...item, Price: newPrice };
            }
            return item;
          }),
        },
      }));
    } else {
      this.setState((prevState) => ({
        editingNode: {
          ...prevState.editingNode,
          TermLengths: [
            ...prevState.editingNode.TermLengths,
            {
              TermLength: termLengthToUpdate,
              Price: isNaN(newPrice) || newPrice < 0 ? 0 : newPrice,
              TableId: 0,
              TableGuid: "00000000-0000-0000-0000-000000000000",
              StoreListingId: -1,
              IsActive: false,
              LastModificationDate: new Date(0),
            },
          ],
        },
      }));
    }
  };

  termLengthToNumber = (term: StoreListingTermRange): number => {
    switch (term) {
      case StoreListingTermRange.ThreeMonths:
        return 3;
      case StoreListingTermRange.SixMonths:
        return 6;
      case StoreListingTermRange.TwelveMonths:
        return 12;
      case StoreListingTermRange.Permanent:
        return -1;
      default:
        return 0;
    }
  };
  termActiveChanged = (changedTerm: StoreListingTermRange, newVal: boolean) => {
    this.toggleActiveForTermLength(this.termLengthToNumber(changedTerm), newVal);
  };

  termPriceChanged = (changedTerm: StoreListingTermRange, newVal: number) => {
    this.updatePriceForTermLength(this.termLengthToNumber(changedTerm), newVal);
  };

  toggleActiveForTermLength = (termLengthToUpdate: number, newVal: boolean) => {
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        TermLengths: prevState.editingNode.TermLengths.map((item) => {
          if (item.TermLength === termLengthToUpdate) {
            return { ...item, IsActive: newVal };
          } else {
            return item;
          }
        }),
      },
    }));
  };
  formValid = () => {
    if (
      this.state.editingNode.StoreListing.Name === "" ||
      this.state.editingNode.StoreListing.EndDate <= this.state.editingNode.StoreListing.StartDate ||
      this.state.editingNode.StoreListing.ProductId <= 0 ||
      this.state.editingNode.TermLengths.filter((x) => x.IsActive).length === 0 ||
      this.state.editingNode.StoreListing.Description.length > 1000 ||
      this.state.editingNode.StoreListing.Name.length > 100
    ) {
      return false;
    }
    return true;
  };

  // #region Product
  private initializeProduct = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.state.editingNode.Customer === null) {
        reject();
        return;
      }
      let result = await this.context.flowProducts({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.props.publisherId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private productFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowProducts({ FlowRequest: request.Batches[0], PublisherId: this.props.publisherId });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private generateProduct = (n: INode): JSX.Element => {
    let node = n as Models.IProductViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.ProductDef.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={1} className="centerText" key={1} value={node.ProductDef.MasterCode} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={2} value={node.ProductDef.ProductCode} />);
    dataItems.push(<DataItem flexVal={3} className="" key={3} value={node.ProductDef.Name} />);
    let startDate = Convert.dateToFormattedString(node.ProductDef.StartDate, Languages.English);
    dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={4} value={startDate} />);

    let value = "";
    if (node.ProductDef.EndDate === null) {
      value = "Never";
    } else if (new Date(node.ProductDef.EndDate) < new Date()) {
      value = "Expired";
    } else {
      value = Convert.dateToFormattedString(node.ProductDef.EndDate, Languages.English);
    }

    if (value === "Expired") {
      dataItems.push(
        <DataItem flexVal={2} className="rightBorder leftBorder centerText" key={5} value={null}>
          <span style={{ color: "red" }}>{value}</span>
        </DataItem>
      );
    } else if (value === "Never") {
      dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText bolded" key={5} value={value} />);
    } else {
      dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={5} value={value} />);
    }

    return (
      <DataRow
        className={this.state.editingNode.ProductDef !== null && node.ProductDef.TableId === this.state.editingNode.StoreListing.ProductId ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.selectProduct}
      />
    );
  };
  private selectProduct = (n: INode) => {
    let node = n as Models.IProductViewModel;
    this.setState(
      (prevState) => ({
        editingNode: {
          ...prevState.editingNode,
          StoreListing: {
            ...prevState.editingNode.StoreListing,
            ProductId: node.ProductDef.TableId,
          },
        },
      }),
      () => {
        this.productTable.current!.reRender();
      }
    );
  };
  private productQueryExecute = (query: string) => {
    this.productTable.current!.reload(query);
  };

  private reviewFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowStoreListingReviews({
        FlowRequest: request.Batches[0],
        StoreListingId: this.state.editingNode.StoreListing.TableId,
        PublisherId: this.props.publisherId,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        reject();
      }
    });

  private initializeReviews = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.state.editingNode.TableId === -1) {
        reject();
        return;
      }
      let result = await this.context.flowStoreListingReviews({
        FlowRequest: {
          Action: Action.insert,
          AnchorMainId: 0,
          Nodes: [],
          BatchSize: 75,
          TargetMainId: 0,
          Query: query,
        },
        StoreListingId: this.state.editingNode.StoreListing.TableId,
        PublisherId: this.props.publisherId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private generateReview = (n: INode) => {
    let node = n as Models.IStoreListingReview;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={1} className="" key={1} value={node.UserId.toString()} />);
    dataItems.push(<DataItem flexVal={1} className={""} key={2} value={node.UserName} />);
    dataItems.push(<DataItem flexVal={2} className="" key={3} value={node.ReviewText} />);
    dataItems.push(<DataItem flexVal={1} className="" key={4} value={Convert.dateToFormattedString(node.ReviewDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={1} className="" key={5} value={node.Rating.toString()} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} />;
  };

  handleMDEditorInput = (val?: string) => {
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        StoreListing: {
          ...prevState.editingNode.StoreListing,
          Description: val ?? "",
        },
      },
    }));
  };

  handleTagsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, StoreListing: { ...prevState.editingNode.StoreListing, Tags: e.target.value.split(",") } },
    }));
  };

  handleSaveStoreListing = async () => {
    const initialTermLengths = this.props.initialNode.TermLengths;
    const editingTermLengths = this.state.editingNode.TermLengths;

    const priceChanges = initialTermLengths.reduce(
      (changes, initialTerm) => {
        const editingTerm = editingTermLengths.find((term) => term.TermLength === initialTerm.TermLength);
        if (editingTerm && initialTerm.Price !== editingTerm.Price) {
          changes.push({
            term: initialTerm.TermLength,
            oldPrice: initialTerm.Price,
            newPrice: editingTerm.Price,
          });
        }
        return changes;
      },
      [] as Array<{ term: number; oldPrice: number; newPrice: number }>
    );

    if (priceChanges.length > 0) {
      const changeDetails = priceChanges.map((change, index) => (
        <li key={index}>
          Term: {change.term === -1 ? "Permanent" : `${change.term} months`}, Price: {change.oldPrice} credits → {change.newPrice} credits
        </li>
      ));

      let result = await Messages.Dialog.confirm(
        <div>
          <span>Are you absolutely sure you wish to update these prices?</span>
          <ul>{changeDetails}</ul>
        </div>,
        "Update prices?"
      );
      if (result !== "true") {
        return;
      }
    }

    this.props.saveRequested!(this.state.editingNode);
  };

  private initializeSales = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowStoreListingTermSales({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.props.publisherId,
        StoreListingId: this.state.editingNode.StoreListing.TableId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private salesFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowStoreListingTermSales({
        FlowRequest: request.Batches[0],
        PublisherId: this.props.publisherId,
        StoreListingId: this.state.editingNode.StoreListing.TableId,
      });
      if (result.valid()) {
        resolve({
          Batches: [Convert.indexify(result.data.FlowResponse)],
        });
      } else {
        reject();
      }
    });

  private saleQueryExecute = (query: string) => {
    this.salesTable.current!.reload(query);
  };
  generateSale = (n: INode) => {
    let node = n as Models.IStoreListingTermSaleViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TermSale.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(
      <DataItem
        flexVal={2}
        key={1}
        className="rightBorder leftBorder centerText"
        value={Convert.dateToFormattedString(node.TermSale.StartDate, Languages.English)}
      />
    );
    if (new Date(node.TermSale.EndDate) < new Date()) {
      dataItems.push(
        <DataItem flexVal={2} className="centerText" key={2} value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.TermSale.EndDate, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(<DataItem flexVal={2} key={2} className="centerText" value={Convert.dateToFormattedString(node.TermSale.EndDate, Languages.English)} />);
    }
    dataItems.push(<DataItem flexVal={1} key={3} className="centerText" value={node.TermSale.SalePrice.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={4} className="centerText" value={node.TermSale.IsActive ? "Active" : "Inactive"} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.saleEdit} />;
  };
  addSale = () => {
    let blank: Models.IStoreListingTermSaleViewModel = {
      TermSale: {
        TableId: 0,
        TableGuid: "00000000-0000-0000-0000-000000000000",
        StoreListingTermLengthId: 0,
        StartDate: new Date(),
        EndDate: new Date(),
        SalePrice: 0,
        IsActive: false,
      },
      TermLength: {
        TableId: 0,
        TableGuid: "00000000-0000-0000-0000-000000000000",
        StoreListingId: 0,
        Price: 0,
        TermLength: 0,
        IsActive: false,
        LastModificationDate: new Date(),
      },
      Index: 0,
      IsFirst: false,
      IsLast: false,
    };
    this.setState({
      createMode: true,
      editingSale: blank,
      salesMode: true,
    });
  };

  private saveTermSale = async (e: Models.IStoreListingTermSaleViewModel) => {
    e.PublisherId = this.props.publisherId;
    let response = await this.context.insertOrUpdateStoreListingTermSale({
      TermSale: e.TermSale,
      PublisherId: this.props.publisherId,
    });
    if (response.valid()) {
      Messages.Notify.success(`The item was saved successfully!`);
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private saleEdit = (eNode: INode) => {
    this.setState({
      editingSale: eNode as Models.IStoreListingTermSaleViewModel,
      createMode: false,
      salesMode: true,
    });
  };
  // #endregion

  render() {
    let startDate = Convert.formatDateForForm(new Date(this.state.editingNode.StoreListing.StartDate));
    let endDate = Convert.formatDateForForm(new Date(this.state.editingNode.StoreListing.EndDate));
    let descriptionCharLimitInvalid = this.state.editingNode.StoreListing.Description.length >= 1000 ? "red" : "";
    let nameCharLimitInvalid = this.state.editingNode.StoreListing.Name.length >= 100 ? "red" : "";
    let settings = Models.genericDataSettings;

    if (this.state.salesMode && this.state.editingSale !== null) {
      let saleModel: Models.IStoreListingTermSaleViewModel = JSON.parse(JSON.stringify(this.state.editingSale));
      return (
        <StoreListingSaleForm
          initialNode={saleModel}
          goBackButtonClicked={() => this.setState({ editingSale: null, salesMode: false, activeTab: StorefrontTab.Sales })}
          publisherId={this.props.publisherId}
          storeListingId={this.state.editingNode.StoreListing.TableId}
          isEditing={true}
          saveRequested={this.saveTermSale}
        />
      );
    }

    return (
      <div className="full-width full-height storefrontForm">
        <h2>Store Listing Management</h2>
        <Tabs defaultActiveKey={this.state.activeTab} id="storefrontTabs">
          <Tab eventKey={StorefrontTab.Info} title={"Information"}>
            {this.props.creatingMode && <p> You have chosen to create a new store listing for this publisher. Enter all the properties and save them. </p>}
            {!this.props.creatingMode && <p> You can view and edit the properties of this store listing. </p>}
            <Form>
              <Col>
                <Row>
                  <FormGroup style={{ flex: "1" }}>
                    <Label for="name">
                      Store listing Name. Characters remaining:{" "}
                      {<span style={{ color: nameCharLimitInvalid }}>{100 - this.state.editingNode.StoreListing.Name.length}</span>}{" "}
                    </Label>
                    <Input onChange={this.handleNameChanged} type="text" value={this.state.editingNode.StoreListing.Name} name="name" id="name" width="8px" />
                  </FormGroup>
                </Row>
                <FormGroup>
                  <p>Enter tags representing your product&apos;s themes. Comma separated. Tags are case insensitive.</p>
                  <Input value={this.state.editingNode.StoreListing.Tags.join(",")} onChange={this.handleTagsChanged} placeholder="Comma separated tags" />
                </FormGroup>
                <p style={{ marginTop: "15px" }}>
                  Store listing Description. Characters remaining:{" "}
                  {<span style={{ color: descriptionCharLimitInvalid }}>{1000 - this.state.editingNode.StoreListing.Description.length}</span>}
                </p>
                <ProLibroMarkdown value={this.state.editingNode.StoreListing.Description} onChange={this.handleMDEditorInput} />
                <Row>
                  <FormGroup style={{ flex: "1" }}>
                    <Label for="startDate">Start Date </Label>
                    <Input onChange={this.startDateChanged} type="date" value={startDate} name="startDate" id="startDate" width="8px" />
                  </FormGroup>
                  <FormGroup style={{ flex: "1" }}>
                    <Label for="endDate">End Date </Label>
                    <Input onChange={this.endDateChanged} type="date" value={endDate} name="endDate" id="endDate" width="8px" />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup check style={{ flex: "1" }}>
                    <Label check for="status">
                      <Input
                        onChange={this.statusChanged}
                        type="checkbox"
                        checked={this.state.editingNode.StoreListing.IsActive}
                        name="status"
                        id="status"
                        width="8px"
                      />
                      Store listing is Active{" "}
                    </Label>
                  </FormGroup>
                  {this.context.canManageSystem() && (
                    <FormGroup check style={{ flex: "1" }}>
                      <Label check for="status">
                        <Input
                          onChange={this.isSpotlightChanged}
                          type="checkbox"
                          checked={this.state.editingNode.StoreListing.IsSpotlight}
                          name="spotlight"
                          id="spotlight"
                          width="8px"
                        />
                        Spotlight Product
                      </Label>
                    </FormGroup>
                  )}
                </Row>
                <h2 style={{ marginTop: "15px" }}>Terms</h2>
                <p>
                  The terms are the lengths of time that a purchased license should be active. Terms allow you to sell your product for less if your users only
                  need the license for a certain time.
                </p>
                <div className="termHolder">
                  <StoreTerm
                    activeChanged={this.termActiveChanged}
                    priceChanged={this.termPriceChanged}
                    term={StoreListingTermRange.ThreeMonths}
                    currentPrice={this.state.editingNode.TermLengths?.find((term) => term.TermLength === 3)?.Price}
                    isActive={this.state.editingNode.TermLengths?.find((term) => term.TermLength === 3)?.IsActive}
                  />
                  <StoreTerm
                    activeChanged={this.termActiveChanged}
                    priceChanged={this.termPriceChanged}
                    term={StoreListingTermRange.SixMonths}
                    currentPrice={this.state.editingNode.TermLengths?.find((term) => term.TermLength === 6)?.Price}
                    isActive={this.state.editingNode.TermLengths?.find((term) => term.TermLength === 6)?.IsActive}
                  />
                  <StoreTerm
                    activeChanged={this.termActiveChanged}
                    priceChanged={this.termPriceChanged}
                    term={StoreListingTermRange.TwelveMonths}
                    currentPrice={this.state.editingNode.TermLengths?.find((term) => term.TermLength === 12)?.Price}
                    isActive={this.state.editingNode.TermLengths?.find((term) => term.TermLength === 12)?.IsActive}
                  />
                  <StoreTerm
                    activeChanged={this.termActiveChanged}
                    priceChanged={this.termPriceChanged}
                    term={StoreListingTermRange.Permanent}
                    currentPrice={this.state.editingNode.TermLengths?.find((term) => term.TermLength === -1)?.Price}
                    isActive={this.state.editingNode.TermLengths?.find((term) => term.TermLength === -1)?.IsActive}
                  />
                </div>
                <h2 style={{ marginTop: "15px" }}> Associated Product </h2>
                <Row>
                  <DataTable
                    headers={["Master Code", "Product Code", "Name", "Product Start Date", "Product End Date"]}
                    headerFlexes={[1, 1, 3, 2, 2]}
                    flowProvider={this.productFlowProvider}
                    initializeFlowProvider={this.initializeProduct}
                    objectBuilder={this.generateProduct}
                    ref={this.productTable}
                    settingsOverride={Models.genericDataSettings}
                    searchQueryComitted={this.productQueryExecute}
                  />
                </Row>
              </Col>
            </Form>
          </Tab>
          <Tab eventKey={StorefrontTab.Sales} title={"Sales"}>
            <h1>Sales</h1>
            <p>
              You can run sales for your listing terms. When a sale is active, the targeted listing term will have the sale price instead of the regular price.
              Reader will show added decorations when the term is on sale. Clicking the &quot;+&quot; will take you to the sale creation screen.
            </p>
            <DataTable
              headers={["Start Date", "End Date", "Price", "Status"]}
              headerFlexes={[2, 2, 1, 1]}
              flowProvider={this.salesFlowProvider}
              initializeFlowProvider={this.initializeSales}
              objectBuilder={this.generateSale}
              ref={this.salesTable}
              settingsOverride={settings}
              rowAddRequested={this.addSale}
              searchQueryComitted={this.saleQueryExecute}
            />
          </Tab>
          <Tab eventKey={StorefrontTab.Reviews} title={"Reviews"}>
            <h2 style={{ marginTop: "15px" }}> Reviews </h2>
            <Row>
              <DataTable
                headers={["User ID", "User Name", "Review", "Date Posted", "Rating"]}
                headerFlexes={[1, 1, 2, 1, 1]}
                flowProvider={this.reviewFlowProvider}
                initializeFlowProvider={this.initializeReviews}
                objectBuilder={this.generateReview}
                ref={this.reviewTable}
                settingsOverride={Models.genericDataSettings}
              />
            </Row>
          </Tab>
        </Tabs>
        {this.props.creatingMode && (
          <Button className="choiceButton" disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info">
            Create store listing
          </Button>
        )}
        {!this.props.creatingMode && this.props.saveRequested && (
          <Button
            className="choiceButton"
            disabled={!this.formValid()}
            onClick={() => {
              this.handleSaveStoreListing();
            }}
            color="info"
          >
            Save store listing
          </Button>
        )}
      </div>
    );
  }
}
