import React from "react";
import { Input, Label } from "reactstrap";
import { Convert } from "src/utilities/Helpers";

const StoreTerm: React.FC<StoreTermProps> = (props) => {
  const printTermFriendly = (): string => {
    switch (props.term) {
      case StoreListingTermRange.ThreeMonths:
        return "3 Months";
      case StoreListingTermRange.SixMonths:
        return "6 Months";
      case StoreListingTermRange.TwelveMonths:
        return "12 Months";
      case StoreListingTermRange.Permanent:
        return "Permanent";
      default:
        return "";
    }
  };
  let price = (
    <span className="priceDetailsLabel">{Convert.toUSDFromStoreCredits(props.currentPrice === undefined ? 0 : props.currentPrice, false) + " per sale."}</span>
  );
  if (props.currentPrice !== undefined && props.currentPrice === 0 && props.isActive === true) {
    price = <span className="priceDetailsLabel">This term will be distributed freely.</span>;
  }
  if (!props.isActive === true) {
    price = (
      <span title="Check the 'Active' checkbox to see price predictions." className="priceDetailsLabel">
        This Term is deactivated.
      </span>
    );
  }

  let classes = "termContainer";
  if (props.isActive) {
    classes += " termActive";
  }
  return (
    <div className={classes}>
      <Label className="termLabel" for="monthTerm" style={{ flex: "1" }}>
        {printTermFriendly()}
      </Label>
      <Label className="termPriceLabel" for="monthTerm" style={{ flex: "1" }}>
        Price (Credits)
        <Input
          style={{ flex: "1" }}
          onChange={(e) => {
            props.priceChanged(props.term, e.target.valueAsNumber);
          }}
          type="number"
          value={props.currentPrice}
          width="8px"
        />
      </Label>
      <Label className="termActiveLabel" check>
        <Input
          onChange={(e) => props.activeChanged(props.term, e.target.checked)}
          type="checkbox"
          checked={props.isActive}
          name="status"
          id="status"
          width="8px"
        />
        Active
      </Label>
      {price}
    </div>
  );
};

export interface StoreTermProps {
  priceChanged: (targetTerm: StoreListingTermRange, price: number) => void;
  activeChanged: (targetTerm: StoreListingTermRange, newValue: boolean) => void;
  term: StoreListingTermRange;
  currentPrice?: number;
  isActive?: boolean;
}

export enum StoreListingTermRange {
  ThreeMonths,
  SixMonths,
  TwelveMonths,
  Permanent,
}

export default StoreTerm;
