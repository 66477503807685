import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { AppSession } from '../../../models/AppSession';
import * as Models from '../../../models/dto/DashboardModels';
import { DataItem, DataRow, DataTable } from '../../../ui/foundation/DataTable';
import { Convert } from '../../../utilities/Helpers';
import { Action, INode, IRequest, IResponse } from '../../foundation/StandaloneCogniflow';
import { AppContext } from '../../state/Contextes';

export interface IStoreListingSaleFormProps {
  initialNode: Models.IStoreListingTermSaleViewModel;
  publisherId: number;
  saveRequested?: (node: Models.IStoreListingTermSaleViewModel) => void;
  reloadSales?: () => void;
  isEditing: boolean;
  goBackButtonClicked: () => void;
  storeListingId: number;
}

export interface IStoreListingSaleFormState {
  editingNode: Models.IStoreListingTermSaleViewModel;
}

export class StoreListingSaleForm extends React.Component<IStoreListingSaleFormProps, IStoreListingSaleFormState> {
  context: AppSession;
  static contextType = AppContext;
  termsTable = React.createRef<DataTable>();

  constructor(props: IStoreListingSaleFormProps) {
    super(props);
    this.state = {
      editingNode: {
        TermSale: props.initialNode.TermSale,
        TermLength: props.initialNode.TermLength,
        Index: props.initialNode.Index,
        IsFirst: props.initialNode.IsFirst,
        IsLast: props.initialNode.IsLast,
      },
    };
  }

  startDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        TermSale: {
          ...prevState.editingNode.TermSale,
          StartDate: new Date(e.target.valueAsDate!),
        },
      },
    }));
  };

  endDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        TermSale: {
          ...prevState.editingNode.TermSale,
          EndDate: new Date(e.target.valueAsDate!),
        },
      },
    }));
  };

  priceChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        TermSale: {
          ...prevState.editingNode.TermSale,
          SalePrice: e.target.valueAsNumber! < 0 ? 0 : e.target.valueAsNumber!,
        },
      },
    }));
  };

  private termsFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowStoreListingTerms({
        FlowRequest: request.Batches[0],
        PublisherId: this.props.publisherId,
        StoreListingId: this.props.storeListingId
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        reject();
      }
    });

  private initializeStoreListingTerms = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.state.editingNode.TermLength.TableId === -1) {
        reject();
        return;
      }
      let result = await this.context.flowStoreListingTerms({
        FlowRequest: {
          Action: Action.insert,
          AnchorMainId: 0,
          Nodes: [],
          BatchSize: 75,
          TargetMainId: 0,
          Query: query,
        },
        PublisherId: this.props.publisherId,
        StoreListingId: this.props.storeListingId
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  selectTerm = (n: INode) => {
    const term = n as Models.IStoreListingTermLengthViewModel;
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, 
        TermLength: term.Term ,
        TermSale: {
            ...prevState.editingNode.TermSale,
            StoreListingTermLengthId: term.Term.TableId,
          },}
    }), () => {
      this.termsTable.current!.reRender();
    });
  };
  private generateTerm = (n: INode) => {
    let node = n as Models.IStoreListingTermLengthViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={1} className="centerText" key={1} value={node.Term.Price.toString()} />);
    dataItems.push(<DataItem flexVal={1} className="centerText" key={2} value={node.Term.TermLength === -1 ? "Permanent" : node.Term.TermLength.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={3} className="centerText" value={node.Term.IsActive ? "Active" : "Inactive"} />);
    dataItems.push(<DataItem flexVal={2} key={4} className="centerText" value={node.StoreListing.Name} />);
    return (
      <DataRow
        className={this.state.editingNode.TermSale.StoreListingTermLengthId === node.Term.TableId ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.selectTerm}
      />
    );
  };
  statusChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
         TermSale: {
          ...prevState.editingNode.TermSale,
          IsActive: e.target.checked,
        },
      },
    }));
  };
  formValid = () => {
    if (this.state.editingNode.TermSale.SalePrice < 0 ||
        this.state.editingNode.TermSale.EndDate < this.state.editingNode.TermSale.EndDate ||
        this.state.editingNode.TermSale.StoreListingTermLengthId <= 0) {
      return false;
    }
    return true;
  };
  
  render() {
    return (
      <div className="full-width full-height">
        <h2>Sale Management</h2>
        <h3>Use this panel to set up sales for your various store terms. These sales will reduce the price of terms while the sale is active.</h3>
        <Form>
          <Col>
          <Row>
            <FormGroup style={{ flex: "1" }}>
                <Label for="price">
                  Sale Price (credits){" "}
                </Label>
                <Input onChange={this.priceChanged} type="number" value={this.state.editingNode.TermSale.SalePrice} name="price" id="price" width="8px" />
              </FormGroup>
              <FormGroup style={{ flex: "1" }}>
                <Label for="startDate">
                  Start Date{" "}
                </Label>
                <Input
                  onChange={this.startDateChanged}
                  type="date"
                  value={Convert.formatDateForForm(new Date(this.state.editingNode.TermSale.StartDate))}
                  name="startDate"
                  id="startDate"
                  width="8px"
                />
              </FormGroup>
              <FormGroup style={{ flex: "1" }}>
                <Label for="endDate">
                  End Date{" "}
                </Label>
                <Input
                  onChange={this.endDateChanged}
                  type="date"
                  value={Convert.formatDateForForm(new Date(this.state.editingNode.TermSale.EndDate))}
                  name="endDate"
                  id="endDate"
                  width="8px"
                />
              </FormGroup>
              
            </Row>
            <Row>
            <FormGroup check style={{ flex: "1" }}>
                <Label check for="status">
                <Input
                  onChange={this.statusChanged}
                  type="checkbox"
                  checked={this.state.editingNode.TermSale.IsActive}
                  name="status"
                  id="status"
                  width="8px"
                />
                  Active{" "}
                </Label>
                
              </FormGroup>
            </Row>
            <h2>Available Terms</h2>
            <Row>
              <DataTable
                headers={["Price", "Term Length", "Status", "Store Listing"]}
                headerFlexes={[1, 1, 1, 2]}
                flowProvider={this.termsFlowProvider}
                initializeFlowProvider={this.initializeStoreListingTerms}
                objectBuilder={this.generateTerm}
                ref={this.termsTable}
                settingsOverride={Models.genericDataSettings}
              />
            </Row>
            
          </Col>
          <Col>
            <Row className="formButtons">
              {this.props.saveRequested && (
                <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                  Save
                </Button>
              )}
              {this.props.goBackButtonClicked && (
                    <Button onClick={this.props.goBackButtonClicked} style={{ flex: "1", marginLeft: "10px " }} outline color="info">
                      Go Back
                    </Button>
                  )}
            </Row>
          </Col>
        </Form>
      </div>
    );
  }
}
